import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    alias: '/distribution',
    showInMenu: true,
    meta: {
        label: 'main_navigation.distribution',
        dataTestAttribute: 'PWDistribution',
        secured: true,
        permissions: [allPermissions.DC_READ],
    },
    children: [{
        alias: 'distribution_channels',
        path: '/distribution/channels',
        showInMenu: true,
        component: lazyLoading('distribution', 'channels/Index'),
        meta: {
            label: 'main_navigation.distribution_channels',
            dataTestAttribute: 'PWDistributionChannels',
            extract: true,
            secured: true,
            permissions: [allPermissions.DC_READ],
        },
        children: [{
            name: 'distribution_channels',
            path: '',
            component: lazyLoading('distribution', 'channels/Table'),
            meta: {
                label: 'main_navigation.distribution_channels',
                secured: true,
                permissions: [allPermissions.DC_READ],
                writePermissions: [allPermissions.DC_WRITE],
            },
        }, {
            name: 'distribution_channels_register',
            path: 'register',
            component: lazyLoading('distribution', 'channels/Form'),
            meta: {
                label: 'main_navigation.distribution_channels',
                secured: true,
                permissions: [allPermissions.DC_WRITE],
            },
        }, {
            name: 'distribution_channels_edit',
            path: ':id/edit',
            component: lazyLoading('distribution', 'channels/Form'),
            meta: {
                label: 'main_navigation.distribution_channels',
                secured: true,
                permissions: [allPermissions.DC_READ],
                writePermissions: [allPermissions.DC_WRITE],
            },
        }],
    }, {
        alias: 'distribution_agencies',
        path: '/distribution/agencies',
        showInMenu: true,
        component: lazyLoading('distribution', 'agencies/Index'),
        meta: {
            label: 'main_navigation.agencies',
            extract: true,
            secured: true,
            permissions: [allPermissions.AGENCY_READ],
        },
        children: [{
            name: 'distribution_agencies',
            path: '',
            component: lazyLoading('distribution', 'agencies/Table'),
            meta: {
                label: 'main_navigation.agencies',
                secured: true,
                permissions: [allPermissions.AGENCY_READ],
                writePermissions: [allPermissions.AGENCY_WRITE],
            },
        }, {
            name: 'distribution_agencies_register',
            path: 'register',
            component: lazyLoading('distribution', 'agencies/Form'),
            meta: {
                label: 'main_navigation.agencies',
                secured: true,
                permissions: [allPermissions.AGENCY_WRITE],
            },
        }, {
            name: 'distribution_agencies_edit',
            path: ':id/edit',
            component: lazyLoading('distribution', 'agencies/Form'),
            meta: {
                label: 'main_navigation.agencies',
                secured: true,
                permissions: [allPermissions.AGENCY_READ],
                writePermissions: [allPermissions.AGENCY_WRITE],
            },
        }],
    }, {
        alias: 'distribution_agencynumbers',
        path: '/distribution/agencynumbers',
        showInMenu: true,
        component: lazyLoading('distribution', 'agencynumbers/Index'),
        meta: {
            label: 'main_navigation.agency_numbers',
            extract: true,
            secured: true,
            permissions: [allPermissions.AGENCY_NUMBER_READ],
        },
        children: [{
            name: 'distribution_agencynumbers',
            path: '',
            component: lazyLoading('distribution', 'agencynumbers/Table'),
            meta: {
                label: 'main_navigation.agency_numbers',
                secured: true,
                permissions: [allPermissions.AGENCY_NUMBER_READ],
                writePermissions: [allPermissions.AGENCY_NUMBER_WRITE],
            },
        }, {
            name: 'distribution_agencynumbers_register',
            path: 'register',
            component: lazyLoading('distribution', 'agencynumbers/Form'),
            meta: {
                label: 'main_navigation.agency_numbers',
                secured: true,
                permissions: [allPermissions.AGENCY_NUMBER_WRITE],
            },
        }, {
            name: 'distribution_agencynumbers_edit',
            path: ':id/edit',
            component: lazyLoading('distribution', 'agencynumbers/Form'),
            meta: {
                label: 'main_navigation.agency_numbers',
                secured: true,
                permissions: [allPermissions.AGENCY_NUMBER_READ],
                writePermissions: [allPermissions.AGENCY_NUMBER_WRITE],
            },
        }],
    }],
};

export default routes;
