import axios from 'axios';
import config from '@/config';
import { PrimaryHotelId } from '@/utils/primaryHotelIdUtils';
import BaseResponse from '@/types/BaseResponse';

export interface BrandAssignmentConfig {
    importsHandling: ImportsHandling;
}
export enum ImportsHandling {
    ALL_BRANDS = 'ALL_BRANDS',
    NO_ASSIGNMENT = 'NO_ASSIGNMENT',
}

export interface ExportUnknownRoomsConfig {
    /** @see fetchUnknownRoomsTypes */
    exportUnknownRooms: string;
}

export interface RoomBoardMatchingAssignment {
    brandConfigId: string;
    roomMatchingConfigId: string;
    boardAutoMatching: boolean;
}

function fetchBrandAssignmentConfig() {
    return axios.get<BaseResponse<BrandAssignmentConfig>>(`${config.api.portfolioBaseUrl}/brandassignment/config/search`);
}

function putBrandAssignmentConfig(payload: BrandAssignmentConfig) {
    return axios.put(`${config.api.portfolioBaseUrl}/brandassignment/config/edit`, payload);
}

function fetchPrimaryHotelIdConfig() {
    return axios.get<BaseResponse<{ primaryHotelId: PrimaryHotelId}>>(`${config.api.portfolioBaseUrl}/primaryhotelid/config/search`);
}

function fetchUnknownRoomsConfig() {
    return axios.get<BaseResponse<ExportUnknownRoomsConfig>>(`${config.api.portfolioBaseUrl}/exportunknownrooms/config/search`);
}

function fetchUnknownRoomsTypes() {
    return axios.get<BaseResponse<Array<string>>>(`${config.api.portfolioBaseUrl}/exportunknownrooms/config/types`);
}

function putUnknownRoomsConfig(payload: ExportUnknownRoomsConfig) {
    return axios.put(`${config.api.portfolioBaseUrl}/exportunknownrooms/config/edit`, payload);
}

function fetchRoomBoardMatchingAssignmentByBrandId(brandId: string) {
    return axios.get<BaseResponse<RoomBoardMatchingAssignment>>(`${config.api.portfolioBaseUrl}/roomboardmatching/assignments/${brandId}`);
}

function fetchAllRoomBoardMatchingAssignments() {
    return axios.get<BaseResponse<Array<RoomBoardMatchingAssignment>>>(`${config.api.portfolioBaseUrl}/roomboardmatching/assignments`);
}

function putAllRoomBoardMatchingAssignments(payload: Array<RoomBoardMatchingAssignment>) {
    return axios.put(`${config.api.portfolioBaseUrl}/roomboardmatching/assignments`, payload);
}

export default {
    fetchAllRoomBoardMatchingAssignments,
    fetchBrandAssignmentConfig,
    fetchPrimaryHotelIdConfig,
    fetchRoomBoardMatchingAssignmentByBrandId,
    fetchUnknownRoomsConfig,
    fetchUnknownRoomsTypes,
    putAllRoomBoardMatchingAssignments,
    putBrandAssignmentConfig,
    putUnknownRoomsConfig,
};
