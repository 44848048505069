<template>
    <li>
        <div class="combi-link" :class="activeClass" :data-test="dataTestAttribute + '_navigationEntry'">
            <router-link
                v-if="item.path"
                :to="{ name }"
                :active-class="activeClass"
                :data-test="dataTestAttribute"
            >
                {{ label }}
            </router-link>
            <span
                v-else
                :class="activeClass"
                :data-test="dataTestAttribute"
            >
                {{ label }}
            </span>

            <button
                class="icon is-small is-angle"
                v-if="visibleChildren.length"
                :aria-expanded="isExpanded"
                @click="isExpanded = !isExpanded"
                data-test="PWExpandNavigation"
            >
                <i class="fa" :class="[isExpanded ? 'fa-angle-down' : 'arrow']"></i>
            </button>
        </div>

        <ul v-if="visibleChildren.length" v-show="isExpanded">
            <panel-recursive-navigation
                v-for="subItem in visibleChildren"
                :key="subItem.name || subItem.alias"
                :item="subItem"
                :disabledNavigationChildren="disabledNavigationChildren"
            />
        </ul>
    </li>
</template>

<script>
import PanelRecursiveNavigation from './RecursiveNavigation.vue';
import { translateRouteLabel } from '@/i18n/utils';

export default {
    name: 'panel-recursive-navigation',
    props: {
        item: {
            type: Object,
            required: true,
        },
        disabledNavigationChildren: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isExpanded: false,
        };
    },
    components: {
        PanelRecursiveNavigation,
    },
    computed: {
        label() {
            return translateRouteLabel(this.item);
        },

        name() {
            return this.item.name || this.item.alias;
        },

        dataTestAttribute() {
            return (this.item.meta && this.item.meta.dataTestAttribute) || '';
        },

        visibleChildren() {
            if (!this.item.children) {
                return [];
            }
            return this.item.children.filter(child => this.itemEnabled(child) && child.showInMenu && this.permissions_sidebarItemHasPermission(child));
        },

        isCurrent() {
            return this.$route.name === this.name;
        },

        activeClass() {
            if (this.isCurrent) {
                return 'is-active';
            } else if (this.isChildActive) {
                return 'has-child-active';
            } else {
                return '';
            }
        },

        /**
         * Determine if a child is active.
         * A child must have a path which is not empty (empty path is the default).
         */
        isChildActive() {
            if (!this.item.children) {
                return false;
            }
            return this.item.children.some(child =>
                typeof child.path === 'string'
                && (child.name || child.alias) === this.$route.name,
            );
        },
    },
    methods: {
        refreshExpanded() {
            this.isExpanded = this.isExpanded || this.isCurrent || this.isChildActive;
        },
        itemEnabled(item) {
            return !this.disabledNavigationChildren?.includes(item.name);
        },
    },
    created() {
        this.refreshExpanded();
    },
    watch: {
        '$route.name'() {
            this.refreshExpanded();
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    display: flex;
    justify-content: space-between;
    color: $color-text;
    font-weight: 400;
    padding: 0 15px;
    text-decoration: none;
    border-bottom: 1px solid $color-border;
    cursor: pointer;

    &:hover {
        background-color: $color-hover;
        color: $color-pw-black;
    }
}

ul ul a {
    padding-left: 30px;
}

ul ul ul a {
    padding-left: 60px;
}

.combi-link {
    display: flex;

    a, span {
        flex: 1 1 auto;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    span {
        font-weight: 400;
        padding: 0.5rem 15px;
        border-bottom: 1px solid $color-border;
    }

    button {
        flex: 0 0 auto;
        background: transparent;
        border: none;
        border-left: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        width: 24px;
        outline: none;
        cursor: pointer;

        &:hover {
            background-color: $color-hover;
            color: $color-pw-black;
        }
    }
}

a.is-active, a.has-child-active, .combi-link.has-child-active > button {
    background-color: $color-primary;
    color: white;

    &:hover {
        background-color: $color-primary-hover;
    }
}

span.has-child-active {
    background-color: $color-primary;
    color: white;
}

.arrow::before {
    content: "\f104";
}
</style>
