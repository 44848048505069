// Store for user-settings will automatically be synced with localStorage
import { createStoreModule } from 'pw-auth';
import config from '@/config';
import mayflyClient from '@/services/http/portfolio/mayflyClient.ts';

const userModule = createStoreModule({
    key: config.localStorage.key,
    lifetime: config.localStorage.lifetime,
    defaultLanguage: config.defaultLanguage,
    initialState: {},
});

const state = {
    language: config.defaultLanguage,
    backRoute: null,
    permissions: [],
    permissionsUpdated: 0,
    portfolio: {
        expanded: {},
        selectedBrandId: null,
        selectedBrand: null,
        selectedRoomConfigName: null,
        selectedPageTab: {
            boardmasterdata: 'codes',
            customRates: 'customRates',
            customRatesRules: 'rates',
            distributionChannel: 'dcAll',
            hotelmasterdata: 'masterData',
            productionSpecification: 'manageSpecifications',
            remarks: 'hotels',
            roommasterdata: 'codes',
            whitelisting: 'main',
        },
        offerView: {
            showDays: 10,
            loses: [7, ''],
            paxInputs: ['30', ''],
        },
        allotmentView: {
            showDays: 365,
            los: 7,
        },
        tableSettings: {
            whitelist: {
                entriesPerPage: 20,
                hiddenCols: ['street', 'currency', 'source', 'travelType', 'programType', 'imageCount', 'descriptionLanguages', 'contractUuid', 'contractName', 'infx'],
            },
            whitelistHistory: { entriesPerPage: 50, hiddenCols: [] },
            assignExtras: { entriesPerPage: 20, hiddenCols: ['source', 'imageCount', 'contractName'] },
            brandCopy: { entriesPerPage: 20, hiddenCols: ['street', 'currency', 'programType', 'hasTaxInfo'] },
            dcAssignment: { entriesPerPage: 20, hiddenCols: ['source', 'contractUuid', 'contractName'] },
            hotelMasterData: { entriesPerPage: 20, hiddenCols: [] },
            events: { entriesPerPage: 50, hiddenCols: [] },
            roomCodesMain: { entriesPerPage: 100, hiddenCols: [] },
            roomCodesSub: { entriesPerPage: 100, hiddenCols: [] },
            roomCodesCustom: { entriesPerPage: 100, hiddenCols: [] },
            boardCodes: { entriesPerPage: 100, hiddenCols: [] },
            extrasAssignment: { entriesPerPage: 20, hiddenCols: [] },
            customRates: { entriesPerPage: 100, hiddenCols: [] },
            remarkHotels: { entriesPerPage: 20, hiddenCols: [] },
            remarksList: { entriesPerPage: 20, hiddenCols: [] },
            extras: { entriesPerPage: 25, hiddenCols: [] },
            goalHotels: { entriesPerPage: 25, hiddenCols: [] },
            distributionChannels: { entriesPerPage: 50, hiddenCols: ['id'] },
            distributionChannelsRoomsModal: { hiddenCols: [] },
            distributionAgencies: { entriesPerPage: 50, hiddenCols: ['id'] },
            distributionAgencynumbers: { entriesPerPage: 50, hiddenCols: ['id'] },
            referencedataPayments: { entriesPerPage: 50, hiddenCols: ['id'] },
            referencedataAddresses: { entriesPerPage: 50, hiddenCols: ['id'] },
            suppliers: { entriesPerPage: 50, hiddenCols: ['id'] },
            brands: { entriesPerPage: 50, hiddenCols: ['id', 'num_splits'] },
            brandsBooking: { entriesPerPage: 50, hiddenCols: ['id'] },
            brandsMultiReference: { entriesPerPage: 50, hiddenCols: ['id'] },
            brandsMultiReferenceOne: { entriesPerPage: 25, hiddenCols: ['id'] },
            brandsMultiReferenceTwo: { entriesPerPage: 25, hiddenCols: ['id'] },
            thirdPartySuppliers: { entriesPerPage: 25, hiddenCols: ['id'] },
            globalTypes: { entriesPerPage: 10, hiddenCols: [] },
            infxEntries: { entriesPerPage: 10, hiddenCols: [] },
            customAirports: { entriesPerPage: 20, hiddenCols: [] },
            packageTravelDirective: { entriesPerPage: 50, hiddenCols: ['id', 'active'] },
            multiReference: { entriesPerPage: 50, hiddenCols: ['id'] }, // fallback
            groupsList: { entriesPerPage: 20, hiddenCols: [] },
            roomDetailsPage: { hiddenCols: ['globalTypes', 'infx'] },
            programTypes: { entriesPerPage: 20, hiddenCols: ['source', 'imageCount'] },
            productionSpecification: { entriesPerPage: 20, hiddenCols: [] },
            roomNames: { entriesPerPage: 20, hiddenCols: [] },
            asc: { entriesPerPage: 20, hiddenCols: ['source', 'imageCount'] },
            preselection: { entriesPerPage: 20, hiddenCols: [] },
            prodSpecRule: { entriesPerPage: 20, hiddenCols: [] },
            productionSpecificationHotelView: { entriesPerPage: 20, hiddenCols: [] },
        },
        selectedFilterset: {
            main: '',
            draft: '',
            whitelist: '',
        },
        feVersion: 0,
    },
};

const mutations = {
    'SET_ENV'(state, payload) {
        Object.assign(state.env, payload);
    },
    'SET_ENV_PORTFOLIO'(state, payload) {
        Object.assign(state.env.portfolio, payload);
    },
    'SET_ENV_EXPANDED'(state, payload) {
        Object.assign(state.env.portfolio.expanded, payload);
    },
    'SET_ENV_PORTFOLIO_TAB'(state, payload) {
        Object.assign(state.env.portfolio.selectedPageTab, payload);
    },
    'SET_BACK_ROUTE'(state, payload) {
        state.env.backRoute = payload;
    },
    'SET_COLUMN_VISIBILITY'(state, payload) {
        const tableSettings = state.env.portfolio.tableSettings[payload.contextName];
        // Append the constraint in the case of description languages
        const keyValue = payload.col.labelConstrain ? payload.col.key + payload.col.labelConstrain : payload.col.key;

        if (payload.col.show) {
            tableSettings.hiddenCols = tableSettings.hiddenCols.filter(col => col !== keyValue);
        } else if (!tableSettings.hiddenCols.includes(keyValue)) {
            tableSettings.hiddenCols.push(keyValue);
        }
    },
    'SET_ENTRY_SETTINGS'(state, payload) {
        state.env.portfolio.tableSettings[payload.contextName].entriesPerPage = payload.entriesPerPage;
    },
    'SET_SELECTED_FILTERSET'(state, payload) {
        if (payload.context && payload.selectedFiltersetId) {
            state.env.portfolio.selectedFilterset[payload.context] = payload.selectedFiltersetId;
        }
    },
    'RESET_SELECTED_FILTERSET'(state, context) {
        if (context) {
            state.env.portfolio.selectedFilterset[context] = '';
        }
    },
    'RESET_SELECTED_FILTERSET_BY_ID'(state, payload) {
        for (const key in state.env.portfolio.selectedFilterset) {
            if (state.env.portfolio.selectedFilterset[key] === payload) {
                state.env.portfolio.selectedFilterset[key] = '';
            }
        }
    },
    'UPDATE_OFFER_VIEW'(state, payload) {
        if (payload._isVue) {
            return;
        }
        Object.assign(state.env.portfolio.offerView, payload);
    },
    'UPDATE_ALLOTMENT_VIEW'(state, payload) {
        if (payload._isVue) {
            return;
        }
        Object.assign(state.env.portfolio.allotmentView, payload);
    },
};

const actions = {
    setLanguage: ({ commit }, language) => {
        commit('SET_ENV', { language });
    },
    setSelectedRoomConfigName: ({ commit }, selectedRoomConfigName) => {
        commit('SET_ENV_PORTFOLIO', { selectedRoomConfigName });
    },
    setSelectedBrandId: ({ commit, dispatch }, selectedBrandId) => {
        commit('SET_ENV_PORTFOLIO', { selectedBrandId, selectedBrand: null });
        dispatch('brandModule/updateAfterSelectedBrandChanged', selectedBrandId, { root: true });
    },
    setSelectedBrand: ({ commit, dispatch }, selectedBrand) => {
        const selectedBrandId = selectedBrand ? selectedBrand.brandConfigId : null;
        commit('SET_ENV_PORTFOLIO', { selectedBrand, selectedBrandId });
        dispatch('brandModule/updateAfterSelectedBrandChanged', selectedBrandId, { root: true });
    },
    setExpanded: ({ commit }, payload) => {
        commit('SET_ENV_EXPANDED', payload);
    },
    setPageTab: ({ commit }, pageTabObject) => {
        commit('SET_ENV_PORTFOLIO_TAB', pageTabObject);
    },
    setBackRoute: ({ commit }, backRoute) => {
        commit('SET_BACK_ROUTE', backRoute);
    },
    setColumnVisibility: ({ commit }, colSettings) => {
        commit('SET_COLUMN_VISIBILITY', colSettings);
    },
    setEntriesPerPage: ({ commit }, entrySettings) => {
        commit('SET_ENTRY_SETTINGS', entrySettings);
    },
    setSelectedFilterset: ({ commit }, filterObject) => {
        commit('SET_SELECTED_FILTERSET', filterObject);
    },
    resetFilterset: ({ commit }, context) => {
        commit('RESET_SELECTED_FILTERSET', context);
    },
    resetFiltersetById: ({ commit }, filtersetId) => {
        commit('RESET_SELECTED_FILTERSET_BY_ID', filtersetId);
    },
    setFeVersion: ({ commit }, feVersion) => {
        commit('SET_ENV_PORTFOLIO', { feVersion });
    },
    updateOfferViewSettings: ({ commit }, payload) => {
        commit('UPDATE_OFFER_VIEW', payload);
    },
    updateAllotmentViewSettings: ({ commit }, payload) => {
        commit('UPDATE_ALLOTMENT_VIEW', payload);
    },
    async fetchPermissions({ commit, dispatch }) {
        let permissions = [];
        try {
            ({ data: permissions } = await mayflyClient.fetchPermissions());
            permissions.sort();
        } catch (error) {
            dispatch('clearPermissions');
            throw error;
        }
        commit('SET_ENV', { permissions, permissionsUpdated: new Date().valueOf() });
    },
    async ensurePermissions({ state, dispatch }, vueInstance) {
        const { permissions, permissionsUpdated = 0 } = state.env;
        const needsRefresh = permissionsUpdated + config.permissions.lifetime < new Date().valueOf();
        if (!needsRefresh) {
            return permissions;
        }
        while (true) {
            try {
                await dispatch('fetchPermissions');
                return;
            } catch (error) {
                vueInstance.$root.handleError(error, 'error_permissions');
                if (error?.response.status === 401) {
                    throw error;
                }
                await new Promise(resolve => setTimeout(resolve, config.permissions.requestRetryTimeout));
            }
        }
    },
    clearPermissions({ commit }) {
        commit('SET_ENV', { permissions: [], permissionsUpdated: 0 });
    },
    setSsoUrl({ commit }, ssoUrl) {
        commit('SET_ENV', { ssoUrl });
    },
};

const getters = {
    language: state => state.env.language,
    selectedBrand: state => state.env.portfolio.selectedBrand,
    selectedBrandId: state => state.env.portfolio.selectedBrandId,
    selectedRoomConfigName: state => state.env.portfolio.selectedRoomConfigName,
    isExpanded: state => key => state.env.portfolio.expanded[key],
    selectedPageTab: state => (pagename) => {
        if (state.env.portfolio?.selectedPageTab && Object.hasOwn(state.env.portfolio.selectedPageTab, pagename)) {
            return state.env.portfolio.selectedPageTab[pagename];
        }
        return null;
    },
    getBackRoute: state => state.env.backRoute,
    getEntriesPerPage: state => (contextName) => {
        return state.env.portfolio.tableSettings[contextName].entriesPerPage;
    },
    isColVisible: state => (contextName, colKey) => {
        return !state.env.portfolio.tableSettings[contextName].hiddenCols.includes(colKey);
    },
    authorizationToken: function (state) {
        try {
            // Access_token is memorized from pw-auth in the user.token object
            return state.env.user.token.access_token;
        } catch (e) {
            return '';
        }
    },
    idToken: function (state) {
        try {
            // Access_token is memorized from pw-auth in the user.token object
            return state.env.user.token.id_token;
        } catch (e) {
            return '';
        }
    },
    selectedFilterset: state => (context) => {
        return state.env.portfolio.selectedFilterset[context];
    },
    feVersion: state => state.env.portfolio.feVersion,
    offerViewSettings: state => state.env.portfolio.offerView,
    allotmentViewSettings: state => state.env.portfolio.allotmentView,
    username: state => state.env.user.profile.username,
    hasPermission: state => (perm, needsAll = false) => {
        if (!Array.isArray(perm)) {
            perm = [perm];
        }
        const iterFunc = needsAll ? Array.prototype.every : Array.prototype.some;
        return iterFunc.call(perm, p => state.env.permissions.includes(p));
    },
    ssoUrl: state => state.env.ssoUrl,
};

export default {
    namespaced: true,
    state: {
        env: Object.assign(userModule.state.env, state),
    },
    mutations: Object.assign(userModule.mutations, mutations),
    actions: Object.assign(userModule.actions, actions),
    getters: Object.assign(userModule.getters, getters),
};
