import type { PwRouteCategory, PwRouteConfig } from '@/types/routes';
import Permission from '@/types/permission';

/**
 * Iterate over the given sidebar item
 * and merge its children's permissions into the route's permissions itself,
 * iff it is secured.
 */
export function mergeChildPermissionsRecursively(item: PwRouteCategory | PwRouteConfig): Set<Permission> | null {
    const permissions: Set<Permission> = new Set();
    let childHasNoPermissions = false;

    if (!item.meta) {
        item.meta = {};
    }
    if (item.meta.permissions) {
        return new Set(item.meta.permissions);
    }

    const subitems
        = (<PwRouteCategory> item).routes
            ?? (<PwRouteConfig> item).children
            ?? [];
    for (const subitem of subitems) {
        const subPermissions = mergeChildPermissionsRecursively(subitem);
        if (!subPermissions) {
            childHasNoPermissions = true;
            continue;
        }
        subPermissions.forEach(p => permissions.add(p));
    }

    if (childHasNoPermissions || !permissions.size) {
        return null;
    }
    item.meta.permissions = Array.from(permissions);
    return permissions;
}

// alias for backwards compatibility
export const allPermissions = Permission;

export default {
    mergeChildPermissionsRecursively,
    allPermissions,
};
