<template>
    <panel-layout-main :title="title" :spaceRight="showProcessSidebar">
        <div slot="aside" data-test="PWPanelSlotAside">
            <panel-category-navigation
                v-for="category in categories"
                :disabledNavigationChildren="disabledNavigationChildren"
                :category="category"
                :key="category.label"
            />
        </div>
        <template slot="header-right">
            <div class="header-right-wrapper">
                <panel-language-selection></panel-language-selection>
                <b-button class="btn btn-sm btn-secondary ml-3" @click="logout()">
                    <i class="fa fa-sign-out" aria-hidden="true"></i> {{ $t('logout') }}
                </b-button>
            </div>
        </template>
        <slot v-if="!migrationsRunning"></slot>
        <migration-display v-else :migrations="migrations"></migration-display>
        <process-sidebar v-if="showProcessSidebar"/>
    </panel-layout-main>
</template>

<script>
import PanelCategoryNavigation from '@/components/CategoryNavigation.vue';
import PanelLanguageSelection from '@/components/LanguageSelection.vue';
import PanelLayoutMain from '@/components/PanelLayoutMain.vue';
import ProcessSidebar from '@/components/ProcessSidebar';
import { mapGetters } from 'vuex';
import MigrationDisplay from '@/components/MigrationDisplay';
import mayflyClient from '@/services/http/portfolio/mayflyClient.ts';
import { translateRouteLabel } from '@/i18n/utils';

export default {
    name: 'panel-layouts-main',
    components: {
        MigrationDisplay,
        PanelCategoryNavigation,
        PanelLanguageSelection,
        PanelLayoutMain,
        ProcessSidebar,
    },
    computed: {
        ...mapGetters({
            routes: 'routesModule/routes',
            migrations: 'portfolioWsModule/migrations',
            goalResolutionDisabledConfig: 'environmentModule/goalResolutionDisabledConfig',
        }),
        title() {
            return translateRouteLabel(this.$route);
        },

        /**
         * All accessible and structured routes with translated labels.
         * @return {Array}
         */
        categories() {
            return this.routes
                .filter(item => item.routes && item.showInMenu && this.permissions_sidebarItemHasPermission(item));
        },
        showProcessSidebar() {
            return (this.$route.meta && this.$route.meta.showProcessSidebar) || false;
        },
        migrationsRunning() {
            return this.migrations && this.migrations.length > 0;
        },
        disabledNavigationChildren() {
            const configDisabledNavChildren = [];
            if (this.goalResolutionDisabledConfig === true) {
                configDisabledNavChildren.push('assign_extras');
            }
            return configDisabledNavChildren;
        },
    },
    methods: {
        async logout() {
            try {
                await mayflyClient.logout();
            } catch (error) {
                console.error(error);
            }
            await this.$store.dispatch('userModule/clearPermissions');
            this.$auth.logout();
        },
    },
};
</script>

<style scoped lang="scss">
.header-right-wrapper {
    display: flex;
    flex-wrap: nowrap;
}
</style>
