export enum PrimaryHotelId {
    GOAL = 'GOAL',
    GIATA = 'GIATA',
}

const DEFAULT_VALUE = PrimaryHotelId.GOAL;

function resolveConfig(value: string) {
    if (value === PrimaryHotelId.GOAL || value === PrimaryHotelId.GIATA) {
        return value;
    }
    console.warn(`Invalid primary hotel id value: ${value}, falling back to ${DEFAULT_VALUE}`);
    return DEFAULT_VALUE;
}

export default {
    GOAL: PrimaryHotelId.GOAL,
    GIATA: PrimaryHotelId.GIATA,
    DEFAULT_VALUE,
    resolveConfig,
};
