import { lazyLoading } from '@/utils/helpers';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    name: 'page_not_found',
    path: '*',
    component: lazyLoading('404'),
    meta: {
        label: 'page_not_found',
    },
};

export default routes;
