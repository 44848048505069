import Vue, { getCurrentInstance, inject } from 'vue';

const TOAST_OBJ = Symbol('toast');
type ToastObj = typeof TOAST_OBJ;

export type ToastPosition
    = 'toast-bottom-center'
    | 'toast-bottom-full-width'
    | 'toast-bottom-left'
    | 'toast-bottom-right'
    | 'toast-top-center'
    | 'toast-top-full-width'
    | 'toast-top-left'
    | 'toast-top-right';

export enum NotificationType {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
}

export interface ToastrOptions {
    // This interface is not exhaustive. Add more as needed.
    // http://s4l1h.github.io/vue-toastr/toast_options.html
    name?: string;
    msg: string;
    title?: string;
    position?: ToastPosition;
    timeout?: number;
    type?: NotificationType;
    onClicked?: () => void;
}

export interface Toastr extends Vue {
    s(msg: string, title?: string): ToastObj;
    e(msg: string, title?: string): ToastObj;
    w(msg: string, title?: string): ToastObj;
    i(msg: string, title?: string): ToastObj;
    Add(options: ToastrOptions): ToastObj;
    Close(toast: ToastObj): void;
    removeByName(name: string): void;
}

const KEY = Symbol('toast_key');

export function useToastr() {
    return inject(KEY, createToastr, true);
}

function createToastr() {
    const inst = getCurrentInstance();
    const root = inst?.proxy.$root;
    if (!root) {
        throw new Error('Couldn\'t find $root. Did you use this inside setup()?');
    }
    /**
     * Get the toastr element from the app container (currently the first child).
     * @see https://www.npmjs.com/package/vue-toastr
     */
    function getToastr() {
        return root?.$children[0].$refs.toastr as Toastr | undefined;
    }

    return {
        getToastr,

        /**
         * Notifies a message.
         * @return the created toast
         */
        notify(msg: string, title?: string, type = NotificationType.SUCCESS): ToastObj | undefined {
            switch (type) {
                case NotificationType.ERROR:
                    return this.notifyError(msg, title);
                case NotificationType.WARNING:
                    return this.notifyWarning(msg, title);
                case NotificationType.INFO:
                    return this.notifyInfo(msg, title);
                default:
                    return this.notifySuccess(msg, title);
            }
        },

        /**
         * Notifies a success message in the toastr container.
         * @param {String} msg
         * @param {String} title
         * @return the created toast
         */
        notifySuccess(msg: string, title?: string): ToastObj | undefined {
            return getToastr()?.s(msg, title);
        },

        /**
         * Notifies an error message in the toastr container.
         * @param {String} msg
         * @param {String} title
         * @return the created toast
         */
        notifyError(msg: string, title?: string): ToastObj | undefined {
            return getToastr()?.e(msg, title);
        },

        /**
         * Notifies an critical error message in the toastr container without timeout
         * User needs to confirm by click it away
         * @param {String} msg
         * @param {String} title
         * @param {function} callback callback function when click is made
         * @return the created toast
         */
        notifyCritical(msg: string, title?: string, callback?: () => void): ToastObj | undefined {
            const name = `${title} ${msg}`;
            const toastr = getToastr();
            // prevent stacking of the same toast
            toastr?.removeByName(name);
            return toastr?.Add({
                name,
                msg,
                title,
                position: 'toast-top-full-width',
                timeout: 0,
                type: NotificationType.ERROR,
                onClicked: callback,
            });
        },

        /**
         * Notifies a warning message in the toastr container.
         * @param {String} msg
         * @param {String} title
         * @return the created toast
         */
        notifyWarning(msg: string, title?: string): ToastObj | undefined {
            return getToastr()?.w(msg, title);
        },

        /**
         * Notifies an info message in the toastr container.
         * @param {String} msg
         * @param {String} title
         * @return the created toast
         */
        notifyInfo(msg: string, title?: string): ToastObj | undefined {
            return getToastr()?.i(msg, title);
        },

        /**
         * Close a previously created toast
         * @param  toast the created toast to close
         */
        close(toast: ToastObj) {
            getToastr()?.Close(toast);
        },
    };
}
