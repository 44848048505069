import { allPermissions } from '@/utils/authUtils';
import { mapGetters } from 'vuex';

/**
 * @deprecated
 * TODO create a replacement
 */
export default {
    computed: {
        /**
         * Convenience alias for the hasPermission getter function.
         * @return {Boolean}
         */
        ...mapGetters({
            permissions_hasPermission: 'userModule/hasPermission',
        }),
        /**
         * Convenience alias for the allPermissions enum to be used in templates.
         * @return {Object}
         */
        permissions_enum() {
            return allPermissions;
        },
        /**
         * Convenience property that checks writePermissions from the route configuration.
         * @return {Boolean}
         */
        permissions_hasWritePermission() {
            const { writePermissions } = this.$route.meta;
            return writePermissions
                ? this.permissions_hasPermission(writePermissions)
                : true;
        },
    },
    methods: {
        permissions_sidebarItemHasPermission(item) {
            if (!item.meta || !item.meta.secured) {
                return true;
            }
            if (item.meta.permissions) {
                return this.permissions_hasPermission(item.meta.permissions);
            }
            return true;
        },
    },
};
