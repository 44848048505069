import axios from 'axios';
import config from '@/config';
import { UserData } from 'pw-auth';
import { DashboardType } from '@/utils/lookerDashboardConstants';

export interface LookerConfig {
    lookerHost: string | null;
    lookerSecret: string | null;
}

export interface LookerDashboard {
    allowedExtraFilters?: Array<string> | null;
    models: Array<string>;
    active?: boolean | null;
    type: DashboardType;
    defaultFilterKey?: string | null;
    defaultFilterValue?: string | null;
    lookerDashboardId: string;
    userAttributeType?: string | null;
}

export interface LookerDashboardActiveState {
    type: string;
    active?: boolean | null;
}

async function getLookerInstance() {
    const config = await getLookerConfig();
    if (config) {
        return config.lookerHost;
    } else {
        return null;
    }
}

function splitNameToParts(name: string) {
    const split = name.split(' ');
    return {
        firstName: split.slice(0, split.length).join(' '),
        lastName: split.length > 1 ? split[split.length - 1] : 'not_set',
    };
}

function getQueryString(user: UserData, host: string, opts?: DashboardOpts) {
    if (!user.profile) {
        throw new Error('logged in user has no profile');
    }
    const { firstName, lastName } = splitNameToParts(user.profile.name);
    const queryParams = new URLSearchParams({
        ...opts ?? {},
        user_id: user.profile.username,
        first_name: firstName,
        last_name: lastName,
        embed_domain: host,
    });
    return '?' + queryParams.toString();
}

async function getLookerConfig() {
    const response = await axios.get<LookerConfig>(`${config.api.confeedUrl}/looker/config`);
    return response.data ?? null;
}

async function saveLookerConfig(lookerConfig: LookerConfig) {
    return axios.put<{config: LookerConfig}>(`${config.api.confeedUrl}/admin/config`, lookerConfig);
}

async function loadAllDashboards(): Promise<Array<LookerDashboard>> {
    const response = await axios.get<Array<LookerDashboard>>(`${config.api.confeedUrl}/admin/dashboards`);
    return response.data ?? [];
}

async function saveSingleDashboard(dashboard: LookerDashboard) {
    return axios.put<never>(`${config.api.confeedUrl}/admin/dashboards`, dashboard);
}

async function getDashboardsActiveState() {
    const response = await axios.get<Array<LookerDashboardActiveState>>(`${config.api.confeedUrl}/looker/dashboards/active`);
    return response.data;
}

async function getAvailableUserAttributes() {
    const response = await axios.get<Array<string>>(`${config.api.confeedUrl}/admin/config/user-attributes`);
    return response.data;
}

async function getAvailableFilters() {
    const response = await axios.get<Array<string>>(`${config.api.confeedUrl}/admin/config/available-filters`);
    return response.data;
}

export type DashboardOpts = Record<string, boolean | number | string | null | undefined>;

async function getDashboardByType(type: DashboardType, user: UserData, host: string, opts?: DashboardOpts) {
    const url = `${config.api.confeedUrl}/looker/dashboards/${type}${getQueryString(user, host, opts)}`;
    const response = await axios.get<string>(url);
    return response.data ?? null;
}

export default {
    getLookerInstance,
    getLookerConfig,
    saveLookerConfig,
    loadAllDashboards,
    saveSingleDashboard,
    getDashboardsActiveState,
    getAvailableFilters,
    getAvailableUserAttributes,
    getDashboardByType,
};
