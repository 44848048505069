const CONTRACT_IMPORT = 'contract_import';
const BRAND_ASSIGNMENT_IN_PROGRESS = 'brandAssignmentInProgress';
const ASC_ASSIGNMENT = 'ascAssignment';
const AUTOMATCHING_CONFIG_CHANGE = 'automatchingConfigChange';
const BRAND_COPY = 'brandcopy';
const DRAFT = 'draft';
const MIGRATIONS = 'migrations';
const WHITELIST = 'wlProcess';
const DISTRIBUTION_CHANNELS_ASSIGNMENT = 'dcAssignment';
const PRODUCTION_SPECIFICATION_ASSIGNMENT = 'productionSpecificationAssignmentInProgress';
const GLOBALTYPES_EXPORT = 'globaltypes_export';
const GLOBAL_EXTRAS_EXPORT = 'global_extras_export';
const GLOBAL_EXTRAS_ASSIGNMENT = 'global_extras_assignment';

const BrandCopyIndex = {
    ANY: null,
    SOURCE: 0,
    TARGET: 1,
};

export default {
    CONTRACT_IMPORT,
    BRAND_ASSIGNMENT_IN_PROGRESS,
    ASC_ASSIGNMENT,
    AUTOMATCHING_CONFIG_CHANGE,
    BRAND_COPY,
    DRAFT,
    MIGRATIONS,
    WHITELIST,
    DISTRIBUTION_CHANNELS_ASSIGNMENT,
    PRODUCTION_SPECIFICATION_ASSIGNMENT,
    GLOBALTYPES_EXPORT,
    GLOBAL_EXTRAS_EXPORT,
    GLOBAL_EXTRAS_ASSIGNMENT,
    BrandCopyIndex,
};
