import axios from 'axios';
import config from '@/config';

export interface PortfolioScope {
    userId?: string | null;
    countryCodes?: Array<string> | null;
    regionIds?: Array<string> | null;
}

async function loadPortfolioScope(username: string) {
    const response = await axios.post<Array<PortfolioScope>>(`${config.api.confeedUrl}/portfolio-scopes/search`, { userIds: [username] });
    if (response.data.length > 0) {
        return response.data[0];
    } else {
        return { countryCodes: [], regionIds: [] };
    }
}

export default {
    loadPortfolioScope,
};
