import { mapGetters } from 'vuex';
import { translateRouteLabel } from '@/i18n/utils';

export default {
    render() {
        // This is a "Renderless" Vue Component
        // it does not render anything to the DOM, except children
        return this.$slots.default;
    },
    data() {
        return {
            defaultWebsiteTitle: 'Peakwork-Panel',
        };
    },
    computed: {
        ...mapGetters({
            environment: 'environmentModule/environment',
        }),
        environmentName() {
            return this.environment && this.environment.name;
        },
        websiteTitle() {
            const segments = [
                translateRouteLabel(this.$route),
                this.defaultWebsiteTitle,
                this.environmentName,
            ].filter(x => x);
            return segments.join(' | ');
        },
    },
    watch: {
        websiteTitle: {
            immediate: true,
            handler(value) {
                document.title = value;
            },
        },
    },
};
