import { lazyLoading } from '@/utils/helpers';
import configs from '@/config';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const resourceName = 'livesupplier';
const path = '/livesupplier';
const registerRoute = 'livesupplier_register';
const editRoute = 'livesupplier_edit';

const brandResourceName = 'livebrandconfig';
const brandPath = '/livebrandconfig';
const brandRegisterRoute = 'livebrandconfig_register';
const brandEditRoute = 'livebrandconfig_edit';

const routes: PwRouteConfig = {
    alias: 'livesupplier',
    showInMenu: configs.features.hybrid,
    meta: {
        label: 'main_navigation.live_search_config',
        secured: true,
        dataTestAttribute: 'PWMenuTouroperatorFulfillment',
    },
    children: [{
        alias: 'livebrandconfig',
        path: brandPath,
        showInMenu: true,
        component: lazyLoading('brandliveconfig'),
        meta: {
            label: 'main_navigation.live_search_config_brand',
            secured: true,
            permissions: [allPermissions.LIVE_BRAND_READ],
            dataTestAttribute: 'PWMenuTouroperatorFulfillment',
        },
        children: [{
            name: 'livebrandconfig',
            path: '',
            component: lazyLoading('brandliveconfig', 'LiveBrandTable'),
            meta: {
                label: 'main_navigation.live_search_config_brand',
                resource: brandResourceName,
                secured: true,
                permissions: [allPermissions.LIVE_BRAND_READ],
                writePermissions: [allPermissions.LIVE_BRAND_WRITE],
            },
        }, {
            name: brandRegisterRoute,
            path: brandPath + '/register',
            component: lazyLoading('brandliveconfig', 'Form'),
            meta: {
                label: 'main_navigation.live_search_config_brand',
                resource: brandResourceName,
                secured: true,
                permissions: [allPermissions.LIVE_BRAND_WRITE],
            },
        }, {
            name: brandEditRoute,
            path: brandPath + '/:id/edit',
            component: lazyLoading('brandliveconfig', 'Form'),
            meta: {
                label: 'main_navigation.live_search_config_brand',
                resource: brandResourceName,
                secured: true,
                permissions: [allPermissions.LIVE_BRAND_READ],
                writePermissions: [allPermissions.LIVE_BRAND_WRITE],
            },
        }],
    }, {
        alias: 'livesupplier',
        path,
        showInMenu: true,
        component: lazyLoading('livesupplier'),
        meta: {
            label: 'main_navigation.live_search_config_supplier',
            secured: true,
            permissions: [allPermissions.LIVE_SUPPLIER_READ],
            dataTestAttribute: 'PWMenuTouroperatorFulfillment',
        },
        children: [{
            name: 'livesupplier',
            path: '',
            component: lazyLoading('livesupplier', 'LiveSupplierTable'),
            meta: {
                label: 'main_navigation.live_search_config_supplier',
                resource: resourceName,
                secured: true,
                permissions: [allPermissions.LIVE_SUPPLIER_READ],
                writePermissions: [allPermissions.LIVE_SUPPLIER_WRITE],
            },
        }, {
            name: registerRoute,
            path: path + '/register',
            component: lazyLoading('livesupplier', 'Form'),
            meta: {
                label: 'main_navigation.live_search_config_supplier',
                resource: resourceName,
                secured: true,
                permissions: [allPermissions.LIVE_SUPPLIER_WRITE],
            },
        }, {
            name: editRoute,
            path: path + '/:id/edit',
            component: lazyLoading('livesupplier', 'Form'),
            meta: {
                label: 'main_navigation.live_supplier',
                resource: resourceName,
                secured: true,
                permissions: [allPermissions.LIVE_SUPPLIER_READ],
                writePermissions: [allPermissions.LIVE_SUPPLIER_WRITE],
            },
        }],
    }],
};

export default routes;
