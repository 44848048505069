import axios from 'axios';

export function setupTimeoutInterceptor() {
    /**
     * Interceptor (hooks in every request and response) to handle Header for all timeout requests
     */
    axios.interceptors.response.use(response => {
        if (response && response.status === 408) {
            throw new axios.Cancel('timeout error');
        }

        return response;
    });
}
