import {
    GoalKeeperCity,
    GoalKeeperCountry,
    LanguageString,
    RegionDetails,
} from '@/services/http/portfolio/addressSearchClient';
import { CityItem, CountryItem, RegionItem } from '@/types/addressSearchItems';
import { HotelCriteria } from '@/types/HotelCriteria';
import { HotelFilterFormData } from '@/types/HotelFormData';
import { Scope } from '@/composables/scope';

function isNameInLanguageFilter(language: string) {
    return (obj: LanguageString) => obj.language === language;
}

export function extractName(nameArray: Array<LanguageString>, language: string, fallbackLanguage = 'en_US') {
    let names = nameArray.filter(isNameInLanguageFilter(language));
    if (!names.length) {
        names = nameArray.filter(isNameInLanguageFilter(fallbackLanguage));
    }
    if (!names.length) {
        names = nameArray;
    }
    return names[0]?.value;
}

export function filterParamsFromScope(filter: Pick<HotelFilterFormData, 'country' | 'region'>, scope: Scope) {
    const filterParams: HotelCriteria = {};
    if (!filter.country && (scope.countryCodes?.length ?? 0) > 0) {
        filterParams.countryCodes = scope.countryCodes;
    }
    if (!filter.region && (scope.regionIds?.length ?? 0) > 0) {
        filterParams.regionIds = scope.regionIds;
    }
    return filterParams;
}

export function mapToRegionItem(region: RegionDetails): RegionItem {
    return {
        regionId: region.regionID,
        regionName: region.regionName,
        regionNameLower: region.regionName.toLowerCase(),
    };
}

export function mapToCountryItem(country: GoalKeeperCountry, language: string): CountryItem {
    const countryName = extractName(country.countryNames, language);
    return {
        countryCode: country.countryCode,
        countryName,
        countryNameLower: countryName.toLowerCase(),
    };
}

export function mapToCityItem(city: GoalKeeperCity, language: string): CityItem {
    const cityName = extractName(city.cityNames, language);
    const regionName = extractName(city.regionNames, language);
    return {
        ...city, // cityId, countryCode, regionId
        cityName,
        regionName,
        label: `${cityName} (${regionName})`,
    };
}

export default {
    extractName,
    filterParamsFromScope,
    mapToRegionItem,
    mapToCountryItem,
    mapToCityItem,
};
