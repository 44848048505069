import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    alias: '/accounts',
    showInMenu: true,
    meta: {
        label: 'main_navigation.accounts',
    },
    children: [{
        name: 'accounts_basic',
        path: '/accounts/basic',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.account_basic',
            resource: 'basicConfiguration',
            visibleFields: [
                'pcpcustomernumber', 'pcx_user', 'pcx_password', 'bhubusername',
            ],
            secured: true,
            permissions: [allPermissions.CONFIG_ACCOUNT_CUSTOMER_READ],
            writePermissions: [allPermissions.CONFIG_ACCOUNT_CUSTOMER_WRITE],
        },
    }, {
        name: 'accounts_hub',
        path: '/accounts/hub',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.account_hub',
            resource: 'hub',
            visibleFields: [
                'ftp_user', 'ftp_password', 'personal_ftp_user', 'personal_ftp_password', 'locales',
                'rating_provider', 'bd4t_url', 'bd4t_timeout', 'solr_data_options', 'additional_solr_data_options',
                'solr_ac_search_options', 'relevance_file_url', 'relevance_file_user', 'relevance_file_password',
                'hotel_category_export_to_solr', 'hotel_rating_export_to_solr', 'traffics_api_key',
            ],
            secured: true,
            permissions: [allPermissions.CONFIG_ACCOUNT_MISC_READ],
            writePermissions: [allPermissions.CONFIG_ACCOUNT_MISC_WRITE],
            dataTestAttribute: 'PWAccountsHub',
        },
    }, {
        name: 'accounts_lpac',
        path: '/accounts/lpac',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.lpac',
            resource: 'lpac',
            visibleFields: [
                'system_lpacid', 'system_urls', 'general_google_maps_client_id', 'general_google_maps_api_key',
            ],
            secured: true,
            permissions: [allPermissions.CONFIG_ACCOUNT_LPAC_READ],
            writePermissions: [allPermissions.CONFIG_ACCOUNT_LPAC_WRITE],
        },
    }, {
        name: 'accounts_booking',
        path: '/accounts/booking',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.booking',
            resource: 'lpac',
            visibleFields: [
                'bhub_pwcustomer', 'bhub_pwoffice', 'bhub_user', 'bhub_password',
            ],
            secured: true,
            permissions: [allPermissions.CONFIG_ACCOUNT_BOOKING_READ],
            writePermissions: [allPermissions.CONFIG_ACCOUNT_BOOKING_WRITE],
        },
    }],
};

export default routes;
