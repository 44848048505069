import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const permissions = [allPermissions.INSIGHTS_READ];

const routes: PwRouteConfig = {
    showInMenu: false,
    name: 'supply_insights_subparent',
    meta: {
        label: 'main_navigation.supply',
        secured: true,
        permissions,
        dataTestAttribute: 'PWSupplyInsights',
    },
    children: [
        {
            name: 'converter_statistics',
            path: '/supply-insights/converter-statistics',
            showInMenu: false,
            component: lazyLoading('supplyInsights', 'ConverterStatistics'),
            meta: {
                label: 'main_navigation.converter_statistics',
                secured: true,
                permissions,
                dataTestAttribute: 'PWConverterStatistics',
            },
        },
        {
            name: 'edf_delivery_statistics',
            path: '/supply-insights/edf-deliveries',
            showInMenu: false,
            component: lazyLoading('supplyInsights', 'EdfDeliveryStatistics'),
            meta: {
                label: 'main_navigation.edf_deliveries',
                secured: true,
                permissions,
                dataTestAttribute: 'PWEdfDeliveryStatistics',
            },
        },
        {
            name: 'supplier_inventory_statistics',
            path: '/supply-insights/supplier-inventory-statistics',
            showInMenu: false,
            component: lazyLoading('supplyInsights', 'SupplierInventoryStatistics'),
            meta: {
                label: 'main_navigation.supplier_inventory',
                secured: true,
                permissions,
                dataTestAttribute: 'PWSupplierInventoryStatistics',
            },
        },
        {
            name: 'contract_import_statistics',
            path: '/supply-insights/contract-import',
            showInMenu: false,
            component: lazyLoading('supplyInsights', 'ContractImportStatistics'),
            meta: {
                label: 'main_navigation.contract_import',
                secured: true,
                permissions,
                dataTestAttribute: 'PWContractImportStatistics',
            },
        },
    ],
};

export default routes;
