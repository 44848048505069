import { LogLevels } from 'vuejs-logger/dist/enum/log-levels';
import { ILoggerOptions } from 'vuejs-logger/dist/interfaces/logger-options';

const isProduction = process.env.NODE_ENV === 'production';

const options: ILoggerOptions = {
    isEnabled: true,
    logLevel: isProduction ? LogLevels.ERROR : LogLevels.DEBUG,
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true,
};

export default options;
