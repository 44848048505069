<template>
    <pw-modal size="rel" :no-fade="true" @hide="modalClose">
        <div slot="modal-header">{{ $t('documentation') }}</div>
        <div slot="modal-body">
            <div v-show="!documentationLoaded">
                <div class="text-center">
                    <pw-loading-indicator height="48px"></pw-loading-indicator>
                </div>
            </div>
            <div v-show="documentationLoaded">
                <pw-card class="notes-card">
                    <h1>{{ $t('release_notes_header') }}</h1>
                    <h4>{{ $t('release_notes_description') }}</h4>

                    <div v-for="releaseNote in releaseNotes"
                         :key="releaseNote.version"
                         @click="handleClick($event)">
                        <h3>{{ $t('version_title') }}: {{releaseNote.version}}</h3>
                        <h4 v-if="releaseNote.releaseDate">{{ $t('release_date_title') }}: {{releaseNote.releaseDate}}</h4>

                        <div v-for="(block, index) of releaseNote.blocks" :key="index">
                            <vue-markdown>{{ block.title }} </vue-markdown>
                            <p v-for="(line, innerIndex) in block.values" :key="innerIndex">
                                <vue-markdown>{{line}}</vue-markdown>
                            </p>
                        </div>
                        <hr>
                    </div>
                </pw-card>
            </div>
        </div>
        <div slot="modal-footer">
            <template>
                <b-button variant="primary" @click="modalClose">{{ $t('close') }}</b-button>
            </template>
        </div>
    </pw-modal>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import PwModal from 'pw-modal';
import PwLoadingIndicator from 'pw-loading-indicator';
import md5 from '../utils/hash';
import VueMarkdown from 'vue-markdown';

export default {
    name: 'documentation-modal',
    components: {
        PwModal,
        PwLoadingIndicator,
        VueMarkdown,
    },
    props: {
        releaseNotes: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [clickaway],
    data() {
        return {
            documentationLoaded: false,
        };
    },
    computed: {
        environmentState() {
            return this.$store.getters['environmentModule/environment'];
        },
    },
    methods: {
        modalClose() {
            this.$emit('update:showModal', false);
        },
        handleClick(event) {
            const url = event.target.href;

            if (url) {
                event.preventDefault();
                window.open(url, '_blank');
            }
        },
    },
    created() {
        if (this.releaseNotes) {
            this.documentationLoaded = true;
        } else {
            const userIdentifier = md5(this.$auth.user.profile.name);

            // notify confeed that the notes have been viewed
            this.$http.post(`${this.$config.api.confeedUrl}/releasenotes`, { userId: userIdentifier, version: this.environmentState.boxVersion })
                .then(() => {
                    this.documentationLoaded = true;
                })
                .catch((err) => {
                    this.$root.handleError(err, 'error_getting_release_notes');
                });
        }
    },
};
</script>

<style scoped lang="scss">
    .notes-card {
        padding: 1em;
    }
</style>
