import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import config from '@/config';
import { PwRouteConfig } from '@/types/routes';

const route: PwRouteConfig = {
    name: 'portfolio',
    path: '/portfolio',
    showInMenu: true,
    component: lazyLoading('portfolio'),
    redirect: '/portfolio/whitelisting',
    meta: {
        label: 'main_navigation.brand_portfolio',
        secured: true,
        dataTestAttribute: 'PWBrandPortfolio',
        permissions: [allPermissions.PORTFOLIO_READ],
    },
    children: [{
        name: 'roomsandboards',
        path: '/portfolio/roomdetail/:hotelId',
        showInMenu: false,
        component: lazyLoading('portfolio', 'RoomDetail/RoomDetail'),
        meta: {
            secured: true,
            showProcessSidebar: true,
            permissions: [allPermissions.PORTFOLIO_READ],
        },
    }, {
        name: 'hoteldetail',
        path: '/portfolio/hoteldetail/:hotelId/:brandConfigId?',
        showInMenu: false,
        component: lazyLoading('portfolio', 'HotelDetail'),
        meta: {
            secured: true,
            showProcessSidebar: true,
            permissions: [allPermissions.PORTFOLIO_READ],
        },
    }, {
        name: 'preselection',
        path: '/portfolio/preselection',
        showInMenu: true,
        component: lazyLoading('portfolio', 'BrandPreselection'),
        meta: {
            label: 'main_navigation.brand_preselection',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.PRESELECTION_READ],
            dataTestAttribute: 'PWPreselection',
        },
    }, {
        name: 'production-specification',
        path: '/portfolio/productionspecification',
        showInMenu: config.features.productionSpecification,
        component: lazyLoading('portfolio', 'ProductionSpecification'),
        meta: {
            label: 'main_navigation.production_specification',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.PRODSPEC_ASSIGNMENT_READ, allPermissions.PRODSPEC_READ],
            dataTestAttribute: 'PWProductionSpecification',
        },
    }, {
        name: 'edit-production-specification',
        path: '/portfolio/productionspecification/edit/:uuid',
        showInMenu: false,
        component: lazyLoading('portfolio', 'ProductionSpecificationForm'),
        props: true,
        meta: {
            label: 'main_navigation.edit_production_specification',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.PRODSPEC_READ],
        },
    }, {
        name: 'add-production-specification',
        path: '/portfolio/productionspecification/new',
        showInMenu: false,
        component: lazyLoading('portfolio', 'ProductionSpecificationForm'),
        meta: {
            label: 'main_navigation.edit_production_specification',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.PRODSPEC_READ],
        },
    }, {
        name: 'whitelisting',
        path: '/portfolio/whitelisting',
        showInMenu: true,
        component: lazyLoading('portfolio', 'Whitelist'),
        meta: {
            label: 'main_navigation.whitelisting',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.DRAFT_READ],
            dataTestAttribute: 'PWPortfolioWhitelisting',
        },
    }, {
        name: 'whitelisting-history',
        path: '/portfolio/whitelisting/history',
        showInMenu: false,
        component: lazyLoading('portfolio', 'WhitelistHistory'),
        meta: {
            label: 'main_navigation.whitelisting_history',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.WHITELIST_HISTORY_READ],
        },
    }, {
        name: 'assign_extras',
        path: '/portfolio/extras',
        showInMenu: true,
        component: lazyLoading('portfolio', 'AssignExtras'),
        meta: {
            label: 'main_navigation.assign_extras',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.EXTRA_ASSIGNMENT_READ],
            dataTestAttribute: 'PWPortfolioAssignExtra',
        },
    }, {
        name: 'assign_distribution_channel',
        path: '/portfolio/distributionchannel',
        showInMenu: true,
        component: lazyLoading('portfolio', 'DistributionChannel'),
        meta: {
            label: 'main_navigation.assign_distribution_channel',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.DC_ASSIGNMENT_READ],
            dataTestAttribute: 'PWPortfolioDistributionChannel',
        },
    }, {
        name: 'assign_program_types',
        path: '/portfolio/programtypes',
        showInMenu: true,
        component: lazyLoading('portfolio', 'EnrichProgramTypes'),
        meta: {
            label: 'main_navigation.program_type_enrichment',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.PROGRAM_TYPE_READ],
            dataTestAttribute: 'PWPortfolioProgramTypeEnrichment',
        },
    }, {
        name: 'exchangerates',
        path: '/portfolio/exchangerates',
        showInMenu: true,
        component: lazyLoading('portfolio', 'Exchangerates'),
        meta: {
            label: 'main_navigation.exchange_rates',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.EXCHANGE_RATE_READ],
            dataTestAttribute: 'PWPortfolioExchangeRates',
        },
    },
    {
        name: 'accommodation_sales_control',
        path: '/portfolio/asc',
        showInMenu: true,
        component: lazyLoading('portfolio', 'AccommodationSalesControl'),
        meta: {
            label: 'main_navigation.accommodation_sales_control',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.ASC_READ],
            dataTestAttribute: 'PWPortfolioAccommodationSalesControl',
        },
    }, {
        name: 'portfolio_scoper',
        path: '/portfolio/scoper',
        showInMenu: true,
        component: lazyLoading('portfolio', 'PortfolioScoper'),
        meta: {
            label: 'main_navigation.scoper',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.SCOPE_READ],
            dataTestAttribute: 'PWPortfolioScoper',
        },
    }],
};

export default route;
