import Vue from 'vue';
import Router from 'vue-router';
import routesItems from './routes';
import { routerGuard, routerHelper } from 'pw-auth';
import store from '@/vuex';

Vue.use(Router);

function generateRoutesFromMenu(menu = [], routes = []) {
    for (const item of menu) {
        if (item.routes && item.routes.length > 0) {
            generateRoutesFromMenu(item.routes, routes);
        } else {
            if (item.path) {
                routes.push(item);
            }

            if (!item.component) {
                generateRoutesFromMenu(item.children, routes);
            }
        }
    }

    return routes;
}

const router = new Router({
    mode: 'history',
    linkActiveClass: 'is-active',
    scrollBehavior: (to, from /* , savedPosition */) => {
        const isSameRoute = to.path === from.path;
        const isSameQuery = to.fullPath === from.fullPath;

        return isSameRoute || isSameQuery
            ? false
            : { y: 0 };
    },
    routes: generateRoutesFromMenu(routesItems),
});

// This is a re-implementation of routerHelper.routePermissionGuard
// that considers the mayfly permissions
// instead of the permissions received from icm.
async function routePermissionGuardCallbackFn(vueInstance, store, to, next) {
    if (!routerHelper.getMeta(to, 'secured', false)) {
        return next();
    }

    try {
        await store.dispatch('userModule/ensurePermissions', vueInstance);
    } catch (error) {
        return next(false);
    }

    const permissions = routerHelper.getMeta(to, 'permissions');
    if (!permissions) {
        return next();
    }

    if (store.getters['userModule/hasPermission'](permissions)) {
        next();
    } else {
        routerHelper.goToAccessDeniedPage(vueInstance);
    }
}

router.beforeEach((to, _from, next) => {
    routerGuard(router.app, store, to, next, {
        routePermissionGuardCallbackFn,
    });
});

export default router;
