import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-composable';
import messagesDeDe from './messages.de_DE';
import messagesEnUs from './messages.en_US';
import moment from 'moment';
import config from '@/config';

export const i18nLanguages = [
    { id: 'de_DE', shortId: 'de' },
    { id: 'en_US', shortId: 'en' },
];

Vue.use(VueI18n);
moment.locale(i18nLanguages.map(l => l.shortId));

const TRANSLATION_MAPPING = i18nLanguages
    .reduce((result, language) => {
        result[language.id] = language.shortId;
        return result;
    }, {});

export const i18n = createI18n({
    messages: {
        de_DE: messagesDeDe,
        en_US: messagesEnUs,
    },
    locale: config.defaultLanguage,
    fallbackLang: config.defaultLanguage,
});

export const i18nMixin = {
    computed: {
        language() {
            return this.$store.getters['userModule/language'];
        },
    },
    watch: {
        language(language, oldLanguage) {
            if (language !== oldLanguage) {
                this.$i18n.locale = language;
                moment.locale(TRANSLATION_MAPPING[language]);
                // this.$validator.setLocale(TRANSLATION_MAPPING[language]);
            }
        },
    },
    created() {
        this.$i18n.locale = this.language;
        moment.locale(TRANSLATION_MAPPING[this.language]);
        // this.$validator.setLocale(TRANSLATION_MAPPING[this.language]);
    },
};

export default i18n;
