import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    name: 'fulfillment',
    path: '/fulfillment',
    showInMenu: true,
    component: lazyLoading('configuration', 'Fulfillment'),
    meta: {
        label: 'main_navigation.fulfillment',
        secured: true,
        permissions: [allPermissions.FULFILLMENT_READ],
        writePermissions: [allPermissions.FULFILLMENT_WRITE],
        dataTestAttribute: 'PWMenuTouroperatorFulfillment',
    },
};

export default routes;
