import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

/**
 * modules
 */
import brandModule from './modules/brand';
import userModule from './modules/user';
import routesModule from './modules/routes';
import externalModule from './modules/external';
import portfolioWsModule from './modules/portfolioWs';
import environmentModule from './modules/environment';
import insightsModule from './modules/insights';
import hotelFilterModule from './modules/hotelFilter';
import completionsModule from './modules/completions';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        brandModule,
        userModule,
        routesModule,
        externalModule,
        portfolioWsModule,
        environmentModule,
        insightsModule,
        hotelFilterModule,
        completionsModule,
    },
    // add here all paths to states you want to persist in local storage
    plugins: [createPersistedState({ key: 'panel', paths: ['userModule'] })],
});
