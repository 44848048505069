import i18n from '@/i18n';

/**
 * Translates the label of the route using the selected language
 * @param route
 * @returns {VueI18n.TranslateResult}
 */
export function translateRouteLabel(route) {
    const label = route.meta && route.meta.label;
    return i18n.t(label || route.name);
}
