import { lazyLoading } from '@/utils/helpers';
import configs from '@/config';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    alias: '/configuration',
    showInMenu: true,
    meta: {
        label: 'main_navigation.expert',
        dataTestAttribute: 'PWExpert',
    },
    children: [{
        name: 'configuration_lpac',
        path: '/configuration/lpac',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.configuration_lpac',
            resource: 'lpac',
            visibleFields: [
                'name', 'general_fromMail', 'general_replyToMail', 'i18n_default_language',
                'general_desktopText', 'general_desktopTextColor', 'general_backgroundColor',
                'infx_outputbasedir', 'infx_reportsloadfromlocal', 'infx_version',
                'travelviewer_travelapplicantinformation',
            ],
            secured: true,
            permissions: [allPermissions.CONFIG_EXPERT_LPAC_READ],
            writePermissions: [allPermissions.CONFIG_EXPERT_LPAC_WRITE],
        },
    }, {
        name: 'configuration_hub',
        path: '/configuration/hub',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.configuration_hub',
            resource: 'hub',
            visibleFields: [
                'hub_code', 'load_cache_from_file',
            ],
            secured: true,
            permissions: [allPermissions.CONFIG_EXPERT_HUB_READ],
            writePermissions: [allPermissions.CONFIG_EXPERT_HUB_WRITE],
            dataTestAttribute: 'PWExpertHub',
        },
    }, {
        name: 'configuration_dhub',
        path: '/configuration/dhub',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.configuration_dhub',
            resource: 'dhub',
            hiddenFields: ['id', 'active', 'bookinghandovertype',
                'midocowsdl', 'midocoservice', 'midocologin', 'midocopassword', 'midocoauthtoken', 'midocoorganisationunit', 'midoco_package_supplier_code', 'midoco_agency_no',
                'midoco_use_ext_tx_key', 'midoco_ext_tx_key_type', 'midoco_ext_tx_key_push_name_attrib', 'midoco_ext_id_as_transfer_id',
                'bookingxmlpuship', 'bookingxmlpushport', 'bookingxmlpushpath', 'bookingxmlpushhttps', 'bookingxmlpushuser', 'bookingxmlpushpassword',
                'bookingxmlpushcron', 'bookingxmlpushmaxretries', 'bookingxmlcancelpushactive', 'bookingxmlcancelpushpath', 'priceequalityrules'],
            secured: true,
            permissions: [allPermissions.CONFIG_EXPERT_DHUB_READ],
            writePermissions: [allPermissions.CONFIG_EXPERT_DHUB_WRITE],
        },
    }, {
        name: 'configuration_dhubdatamix',
        path: '/configuration/dhubdatamix',
        showInMenu: configs.features.dhubDatamix,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.configuration_dhubdatamix',
            resource: 'dhubdatamix',
            hiddenFields: ['id', 'active'],
            secured: true,
            permissions: [allPermissions.CONFIG_EXPERT_DHUB_READ],
            writePermissions: [allPermissions.CONFIG_EXPERT_DHUB_WRITE],
        },
    }, {
        name: 'configuration_dhubcancellationrules',
        path: '/configuration/dhubcancellationrules',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.configuration_dhubcancellationrules',
            resource: 'dhubcancellationrules',
            hiddenFields: ['id', 'active'],
            secured: true,
            permissions: [allPermissions.CONFIG_EXPERT_DHUB_READ],
            writePermissions: [allPermissions.CONFIG_EXPERT_DHUB_WRITE],
        },
    }, {
        name: 'configuration_player',
        path: '/configuration/player',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.configuration_player',
            resource: 'player',
            hiddenFields: [
                'id',
                'active',
                'name',
            ],
            autocompleteFields: {
                'additionalattributes.key': 'additionalattributes',
            },
            secured: true,
            permissions: [allPermissions.CONFIG_EXPERT_PLAYER_READ],
            writePermissions: [allPermissions.CONFIG_EXPERT_PLAYER_WRITE],
        },
    }],
};

export default routes;
