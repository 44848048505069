import axios from 'axios';
import store from '../vuex';

export function tokenInterceptor(config) {
    const offset = -new Date().getTimezoneOffset() / 60;

    config.headers['X-UtcOffset'] = offset.toString();
    config.headers.Pragma = 'no-cache';
    config.headers['Accept-Language'] = store.getters['userModule/language'].replace('_', '-');
    config.headers.Authorization = `Bearer ${store.getters['userModule/authorizationToken']}`;
    return config;
}

export function setupTokenInterceptor() {
    /**
     * Interceptor (hooks in every request and response) to handle Header for all requests
     * adds JWT-Token in Header
     */
    axios.interceptors.request.use(tokenInterceptor);
}
