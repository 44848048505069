<template>
    <div class="app-container">
        <pw-header>
            <div class="header__left">
                <slot name="header-left"><h1>{{ title }}</h1></slot>
            </div>
            <div class="header__right">
                <slot name="header-right"></slot>
            </div>
        </pw-header>
        <panel-layout-aside>
            <slot name="aside"></slot>
        </panel-layout-aside><!-- leave this comment as is
    --><main
            class="container-fluid"
            :class="{ 'space-right': spaceRight }"
            data-test="PWMainPageContent"
        >
            <slot></slot>
        </main>
    </div>
</template>

<script>
import PwHeader from 'pw-header';
import PanelLayoutAside from './PanelLayoutAside.vue';

export default {
    name: 'panel-layout-main',
    components: {
        PwHeader,
        PanelLayoutAside,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        spaceRight: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
    @import "../assets/scss/variables.scss";

    main {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - #{$menu-width});
        padding: 15px;

        @media print {
            display: block;
            width: 100%;
            padding-bottom: 0;
        }
    }

    h1 {
        font-size: 1.5rem;
        margin: 0;
        color: $color-pw-blue;
    }

    .space-right {
        padding-right: 50px;
    }
</style>
