/**
 * Common hashfunctions for Javascript
 * Warning: Message length should not exceed 4GB because 32 Bit integers are used for the
 * -normally used- 64 Bit message length
 * @requires MD5.js (PEAKWORK.UTIL)
 * @requires SHA2.js (PEAKWORK.UTIL)
 * @author: Jan Hoffmann <jan.hoffmann@peakwork.com>
 * @version: 1.0
 */

import md5 from './MD5';

export default md5;
