import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    name: 'events',
    path: '/events',
    showInMenu: true,
    component: lazyLoading('events'),
    meta: {
        label: 'main_navigation.events',
        secured: true,
        permissions: [allPermissions.EVENTS_READ],
        dataTestAttribute: 'PWOperationEvents',
    },
};

export default routes;
