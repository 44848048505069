import { BvModal } from 'bootstrap-vue';
import { getCurrentInstance } from 'vue';

export function useBvModal(): BvModal {
    const inst = getCurrentInstance();
    if (inst) {
        return inst.proxy.$bvModal;
    }
    throw new Error('must be used inside setup()');
}
