import cloneDeep from 'lodash/cloneDeep';
import lockingUtils from '@/utils/lockingUtils';

const state = {
    progress: {},
};

const mutations = {
    SET_PROGRESS_ELEMENT: (state, payload) => {
        if (payload.type && payload.jobs) {
            const cloneIt = cloneDeep(state.progress);
            cloneIt[payload.type] = payload.jobs;
            state.progress = cloneIt;
        }
    },
};

const actions = {
    setProgress: (context, payload) => {
        context.commit('SET_PROGRESS_ELEMENT', payload);
    },
};

const getters = {
    // ################################### get stored processes ###################################

    allProcesses: state => mapElements(state.progress),
    migrations: state => mapMigrations(state),

    // ########################## checks if a single process is running ###########################

    draftInProgressFor: state => brandId => isBrandBlockedForType(brandId, lockingUtils.DRAFT, state),
    whitelistInProgressFor: state => brandId => isBrandBlockedForType(brandId, lockingUtils.WHITELIST, state),
    anyBrandWhitelistInProgress: state => anyBrandInType(lockingUtils.WHITELIST, state),
    distributionChannelAssignmentInProgressFor: state => brandId => isBrandBlockedForType(brandId, lockingUtils.DISTRIBUTION_CHANNELS_ASSIGNMENT, state),
    globalExtrasAssignmentInProgressFor: state => brandId => isBrandBlockedForType(brandId, lockingUtils.GLOBAL_EXTRAS_ASSIGNMENT, state),
    globalTypesExportInProgressFor: state => brandId => isBrandBlockedForType(brandId, lockingUtils.GLOBALTYPES_EXPORT, state),
    anyGlobalTypeExportInProgress: state => anyBrandInType(lockingUtils.GLOBALTYPES_EXPORT, state),
    automatchingConfigChangeInProgress: state => anyJobRunning(lockingUtils.AUTOMATCHING_CONFIG_CHANGE, state),
    brandAssignmentInProgress: state => anyJobRunning(lockingUtils.BRAND_ASSIGNMENT_IN_PROGRESS, state),
    ascAssignmentInProgressFor: state => brandId => isBrandBlockedForType(brandId, lockingUtils.ASC_ASSIGNMENT, state),
    prodSpecAssignmentInProgress: state => anyJobRunning(lockingUtils.PRODUCTION_SPECIFICATION_ASSIGNMENT, state),
    brandCopyInProgressFor: state => (brandId, index = lockingUtils.BrandCopyIndex.ANY) => {
        const copiesRunning = state.progress[lockingUtils.BRAND_COPY];
        if (!copiesRunning) {
            return false;
        }
        const brandEntries
            = index !== lockingUtils.BrandCopyIndex.ANY
                ? copiesRunning.map(e => e.brandConfigIds[index])
                : copiesRunning.flatMap(e => e.brandConfigIds);
        return brandEntries.some(({ brandConfigId }) => brandConfigId === brandId);
    },
    brandsBeingCopied: state => getLockedBrandConfigIds(lockingUtils.BRAND_COPY, state),
    globalExtrasExportInProgressFor: state => brandId => isBrandBlockedForType(brandId, lockingUtils.GLOBAL_EXTRAS_EXPORT, state),

    // ##################################### combined checks ######################################

    enrichmentLocked: (state, getters) => {
        return getters.anyBrandWhitelistInProgress;
    },
    globalTypesLockedFor: (state, getters) => brandId => {
        return getters.brandCopyInProgressFor(brandId)
            || getters.anyGlobalTypeExportInProgress;
    },
    roomBoardsLockedFor: (state, getters) => brandId => {
        return getters.whitelistInProgressFor(brandId)
            || getters.brandCopyInProgressFor(brandId);
    },
    assignExtrasLockedFor: (state, getters) => brandId => {
        return getters.globalExtrasExportInProgressFor(brandId)
            || getters.globalExtrasAssignmentInProgressFor(brandId)
            || getters.brandCopyInProgressFor(brandId);
    },
    distributionChannelsLockedFor: (state, getters) => brandId => {
        return getters.distributionChannelAssignmentInProgressFor(brandId)
            || getters.brandCopyInProgressFor(brandId)
            || getters.whitelistInProgressFor(brandId);
    },
    whitelistingLockedFor: (state, getters) => brandId => {
        return getters.whitelistInProgressFor(brandId)
            || getters.draftInProgressFor(brandId)
            || getters.brandCopyInProgressFor(brandId)
            || getters.distributionChannelAssignmentInProgressFor(brandId)
            || getters.automatchingConfigChangeInProgress;
    },
    draftingLockedFor: (state, getters) => brandId => {
        return getters.draftInProgressFor(brandId)
            || getters.brandCopyInProgressFor(brandId)
            || getters.whitelistInProgressFor(brandId);
    },
    brandCopySourceLockedFor: (state, getters) => brandId => {
        // lock if a write operation is active on this brand
        return getters.draftInProgressFor(brandId)
            || getters.globalExtrasAssignmentInProgressFor(brandId)
            || getters.distributionChannelAssignmentInProgressFor(brandId)
            || getters.brandCopyInProgressFor(brandId, lockingUtils.BrandCopyIndex.TARGET)
            || getters.brandAssignmentInProgress;
    },
    brandCopyTargetLockedFor: (state, getters) => brandId => {
        // lock if a read or write operation is active on this brand
        return getters.draftInProgressFor(brandId)
            || getters.whitelistInProgressFor(brandId)
            || getters.globalExtrasAssignmentInProgressFor(brandId)
            || getters.distributionChannelAssignmentInProgressFor(brandId)
            || getters.brandCopyInProgressFor(brandId)
            || getters.brandAssignmentInProgress;
        // except for the global type export which is very active on some environments
        // || getters.globalTypesExportInProgressFor(brandId);
    },
};

function mapMigrations(state) {
    if (state.progress.migrations) {
        return state.progress.migrations.map(item => {
            return {
                title: item.description || item.jobIdentifier.jobId,
                count: item.currentCount,
                progress: getProgress(item),
            };
        });
    }
    return [];
}

function getLockedBrandConfigIds(type, state) {
    if (state.progress[type]) {
        return state.progress[type].flatMap(e => e.brandConfigIds).filter(e => e.lockStatus).map(e => e.brandConfigId);
    }
    return [];
}

function isBrandBlockedForType(brandId, type, state) {
    if (state.progress[type]) {
        return getLockedBrandConfigIds(type, state).includes(brandId);
    }
    return false;
}

function anyBrandInType(type, state) {
    if (state.progress[type]) {
        return !!state.progress[type].some(e => e.brandConfigIds && e.brandConfigIds.length > 0);
    }
    return false;
}

function anyJobRunning(type, state) {
    if (state.progress[type]) {
        return !!state.progress[type].length > 0;
    }
    return false;
}

function mapElements(progress) {
    return Object.entries(progress).map(entry => {
        const [key, value] = entry;
        const active = value && value.length > 0;
        if (!active) {
            return null;
        }
        return {
            active,
            title: `sidebar.${key}.title`,
            description: `sidebar.${key}.description`,
            abbr: `sidebar.${key}.abbr`,
            type: key,
            processCount: value[0].currentCount,
            progress: getProgress(value[0]),
            startedAt: value[0].startedAt,
            items: value.map(val => mapItem(val)),
        };
    }).filter(e => e !== null);
}

function mapItem(val) {
    return {
        suppliers: val.supplierCodes || [],
        currentCount: val.currentCount,
        progress: getProgress(val),
        brandConfigIds: val.brandConfigIds.map(e => e.brandConfigId),
        description: val.description,
        maxCount: val.maxCount,
    };
}

function getProgress(val) {
    if (val.progress !== null) {
        return Math.floor(val.progress);
    }
    if (val.currentCount !== null && val.maxCount !== null) {
        return Math.floor((val.currentCount / val.maxCount) * 100);
    }
    return null;
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
