import { render, staticRenderFns } from "./PanelLayoutAside.vue?vue&type=template&id=6f1e9e95&scoped=true"
import script from "./PanelLayoutAside.vue?vue&type=script&lang=js"
export * from "./PanelLayoutAside.vue?vue&type=script&lang=js"
import style0 from "./PanelLayoutAside.vue?vue&type=style&index=0&id=6f1e9e95&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f1e9e95",
  null
  
)

export default component.exports