import { lazyLoading } from '@/utils/helpers';
import { PwRouteConfig } from '@/types/routes';
import Permission from '@/types/permission';

function getSupplierRouting(type: string, resourceName: string, permPrefix: string): PwRouteConfig {
    const mainRoute = `suppliers_${type}`;
    const registerRoute = `suppliers_${type}_register`;
    const editRoute = `suppliers_${type}_edit`;
    const path = `/suppliers/${type}`;

    return {
        alias: mainRoute,
        path,
        component: lazyLoading('suppliers'),
        showInMenu: true,
        meta: {
            label: `main_navigation.${type}`,
            secured: true,
            permissions: [Permission[`${permPrefix}_READ` as keyof typeof Permission]],
        },
        children: [{
            name: mainRoute,
            path: '',
            component: lazyLoading('suppliers', 'SupplierTable'),
            meta: {
                label: `main_navigation.${type}`,
                type,
                resource: resourceName,
                secured: true,
                permissions: [Permission[`${permPrefix}_READ` as keyof typeof Permission]],
                writePermissions: [Permission[`${permPrefix}_WRITE` as keyof typeof Permission]],
            },
        }, {
            name: registerRoute,
            path: path + '/register',
            component: lazyLoading('suppliers', 'Form'),
            meta: {
                label: `main_navigation.${type}`,
                type,
                resource: resourceName,
                secured: true,
                permissions: [Permission[`${permPrefix}_WRITE` as keyof typeof Permission]],
            },
        }, {
            name: editRoute,
            path: path + '/:id/edit',
            component: lazyLoading('suppliers', 'Form'),
            meta: {
                label: `main_navigation.${type}`,
                type,
                resource: resourceName,
                secured: true,
                permissions: [Permission[`${permPrefix}_READ` as keyof typeof Permission]],
                writePermissions: [Permission[`${permPrefix}_WRITE` as keyof typeof Permission]],
            },
        }],
    };
}

const routes: PwRouteConfig = {
    alias: '/suppliers',
    showInMenu: true,
    meta: {
        label: 'main_navigation.suppliers',
        dataTestAttribute: 'PWSuppliers',
        extract: true,
    },
    children: [
        getSupplierRouting('hotel', 'supplier', 'LOCALSUPPLIER_HOTEL'),
        getSupplierRouting('flight', 'supplier', 'LOCALSUPPLIER_FLIGHT'),
        getSupplierRouting('insurance', 'thirdpartysupplier', 'INSURANCE'),
        getSupplierRouting('content', 'contentSupplier', 'LOCALSUPPLIER_CONTENT'),
        getSupplierRouting('transfer', 'thirdpartysupplier', 'INSURANCE'),
    ],
};

export default routes;
