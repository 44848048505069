<template>
    <aside class="no-print">
        <slot></slot>
    </aside>
</template>

<script>
export default {
    name: 'panel-layout-aside',
};
</script>

<style scoped lang="scss">
    @import "../assets/scss/variables.scss";

    aside {
        display: inline-block;
        vertical-align: top;
        width: $menu-width;
        border-right: 1px solid $color-border;
        min-height: calc(100vh - #{$header-height} - 2px);
        background-color: white;
    }
</style>
