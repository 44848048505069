import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    alias: '/referencedata',
    showInMenu: true,
    meta: {
        label: 'main_navigation.reference_data',
        dataTestAttribute: 'PWReferenceData',
        extract: true,
        secured: true,
    },
    children: [{
        alias: 'referencedata_addresses',
        path: '/referencedata/addresses',
        component: lazyLoading('referencedata', 'addresses/Index'),
        showInMenu: true,
        meta: {
            label: 'main_navigation.addresses',
            dataTestAttribute: 'PWReferenceDataAddresses',
            extract: true,
            secured: true,
            permissions: [allPermissions.ADDRESS_READ],
        },
        children: [{
            name: 'referencedata_addresses',
            path: '',
            component: lazyLoading('referencedata', 'addresses/Table'),
            meta: {
                label: 'main_navigation.addresses',
                secured: true,
                permissions: [allPermissions.ADDRESS_READ],
                writePermissions: [allPermissions.ADDRESS_WRITE],
            },
        }, {
            name: 'referencedata_addresses_register',
            path: 'register',
            component: lazyLoading('referencedata', 'addresses/Form'),
            meta: {
                label: 'main_navigation.addresses',
                secured: true,
                permissions: [allPermissions.ADDRESS_WRITE],
            },
        }, {
            name: 'referencedata_addresses_edit',
            path: ':id/edit',
            component: lazyLoading('referencedata', 'addresses/Form'),
            meta: {
                label: 'main_navigation.addresses',
                secured: true,
                permissions: [allPermissions.ADDRESS_READ],
                writePermissions: [allPermissions.ADDRESS_WRITE],
            },
        }],
    }, {
        alias: 'referencedata_payments',
        path: '/referencedata/payments',
        component: lazyLoading('referencedata', 'payments/Index'),
        showInMenu: true,
        meta: {
            label: 'main_navigation.payments',
            extract: true,
            secured: true,
            permissions: [allPermissions.PAYMENT_OPTION_READ],
        },
        children: [{
            name: 'referencedata_payments',
            path: '',
            component: lazyLoading('referencedata', 'payments/Table'),
            meta: {
                label: 'main_navigation.payments',
                secured: true,
                permissions: [allPermissions.PAYMENT_OPTION_READ],
                writePermissions: [allPermissions.PAYMENT_OPTION_WRITE],
            },
        }, {
            name: 'referencedata_payments_register',
            path: 'register',
            component: lazyLoading('referencedata', 'payments/Form'),
            meta: {
                label: 'main_navigation.payments',
                secured: true,
                permissions: [allPermissions.PAYMENT_OPTION_WRITE],
            },
        }, {
            name: 'referencedata_payments_edit',
            path: ':id/edit',
            component: lazyLoading('referencedata', 'payments/Form'),
            meta: {
                label: 'main_navigation.payments',
                secured: true,
                permissions: [allPermissions.PAYMENT_OPTION_READ],
                writePermissions: [allPermissions.PAYMENT_OPTION_WRITE],
            },
        }],
    }],
};

export default routes;
