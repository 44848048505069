import axios from 'axios';
import config from '@/config';

export interface ExternalApp {
    name: string;
    url: string;
    isGui: boolean;
}

export function fetchExternalApps() {
    return axios.get<Array<ExternalApp>>(`${config.api.confeedUrl}/externalapps`);
}
