export default {
    id: 'UA-101754339-1',
    userID: 'PEAK2017', // optional
    debug: {
        enabled: false,
        trace: false,
        sendHitTask: true,
    },
    category: {
        portfolio: 'portfolio',
        config: 'config',
        distributionChannel: 'distributionchannel',
    },
    ignoreRoutes: ['home'], // you can exclude some routes name (case insensitive) (optional)
    autoTracking: {
        pageviewTemplate: function (route) {
            return {
                page: route.path,
                title: document.title,
                location: window.location.href,
            };
        },
        exception: true,

    },
};
