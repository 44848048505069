/**
 * MD5.js - md5 support for Javascript
 *
 * @author: Jan Hoffmann <jan.hoffmann@peakwork.com>
 * @version: 1.0
 */

import { toUint32, toLittleEndian32, toHex, safeUintAdd, rotateLeft32 } from '../integer';
import { stringToUtf8Array } from './helper';

class MD5 {
    constructor() {
        this.K = [
            0xd76aa478, 0xe8c7b756, 0x242070db, 0xc1bdceee,
            0xf57c0faf, 0x4787c62a, 0xa8304613, 0xfd469501,
            0x698098d8, 0x8b44f7af, 0xffff5bb1, 0x895cd7be,
            0x6b901122, 0xfd987193, 0xa679438e, 0x49b40821,
            0xf61e2562, 0xc040b340, 0x265e5a51, 0xe9b6c7aa,
            0xd62f105d, 0x02441453, 0xd8a1e681, 0xe7d3fbc8,
            0x21e1cde6, 0xc33707d6, 0xf4d50d87, 0x455a14ed,
            0xa9e3e905, 0xfcefa3f8, 0x676f02d9, 0x8d2a4c8a,
            0xfffa3942, 0x8771f681, 0x6d9d6122, 0xfde5380c,
            0xa4beea44, 0x4bdecfa9, 0xf6bb4b60, 0xbebfbc70,
            0x289b7ec6, 0xeaa127fa, 0xd4ef3085, 0x04881d05,
            0xd9d4d039, 0xe6db99e5, 0x1fa27cf8, 0xc4ac5665,
            0xf4292244, 0x432aff97, 0xab9423a7, 0xfc93a039,
            0x655b59c3, 0x8f0ccc92, 0xffeff47d, 0x85845dd1,
            0x6fa87e4f, 0xfe2ce6e0, 0xa3014314, 0x4e0811a1,
            0xf7537e82, 0xbd3af235, 0x2ad7d2bb, 0xeb86d391,
        ];
        this.R = [
            7, 12, 17, 22, 7, 12, 17, 22, 7, 12, 17, 22, 7, 12, 17, 22,
            5, 9, 14, 20, 5, 9, 14, 20, 5, 9, 14, 20, 5, 9, 14, 20,
            4, 11, 16, 23, 4, 11, 16, 23, 4, 11, 16, 23, 4, 11, 16, 23,
            6, 10, 15, 21, 6, 10, 15, 21, 6, 10, 15, 21, 6, 10, 15, 21,
        ];
        this.padding = [];
        this.padding[0] = 128;
        for (let i = 1; i < 64; i++) {
            this.padding[i] = 0;
        }
        this.string = '';
        this.a0 = toUint32(0x67452301);
        this.b0 = toUint32(0xEFCDAB89);
        this.c0 = toUint32(0x98BADCFE);
        this.d0 = toUint32(0x10325476);
    }

    prepareString(string) {
        this.string = string;
        let counter = 0;
        const stringBuffer = stringToUtf8Array(this.string);
        let strBitLength = toUint32(stringBuffer.length * 8);

        do {
            stringBuffer.push(this.padding[counter++]);
        } while ((stringBuffer.length % 64) !== 56);

        for (let i = 0; i < 4; i++) {
            stringBuffer.push(toUint32(strBitLength & 0xff));
            if (i !== 3) {
                strBitLength = toUint32(strBitLength >> 8);
            }
        }
        for (let i = 0; i < 4; i++) {
            stringBuffer.push(0);
        }
        return stringBuffer;
    }

    calculateM(block) {
        const M = [];
        for (let i = 0; i < 16; i++) {
            const slicedM = block.slice(i * 4, (i + 1) * 4);
            let intM = 0;
            for (let j = 0; j < 4; j++) {
                intM |= slicedM[j];
                if (j !== 3) {
                    intM <<= 8;
                }
            }
            M[i] = toUint32(toLittleEndian32(intM));
        }
        return M;
    }

    calculateDigest(M) {
        let A = this.a0;
        let B = this.b0;
        let C = this.c0;
        let D = this.d0;
        let F;
        let g;
        // mainloop with efficiencyboost. Not RFC 1321
        for (let i = 0; i < 64; i++) {
            if (i <= 15) {
                F = toUint32(D ^ (B & (C ^ D)));
                g = i;
            } else if (i >= 16 && i <= 31) {
                F = toUint32(C ^ (D & (B ^ C)));
                g = ((5 * i) + 1) % 16;
            } else if (i >= 32 && i <= 47) {
                F = toUint32(B ^ C ^ D);
                g = ((3 * i) + 5) % 16;
            } else {
                F = toUint32(C ^ toUint32(B | toUint32(~D)));
                g = (7 * i) % 16;
            }

            const tmp = D;
            D = C;
            C = B;
            B = safeUintAdd(
                B,
                toUint32(
                    rotateLeft32(
                        safeUintAdd(
                            safeUintAdd(A, F),
                            safeUintAdd(this.K[i], M[g]),
                        ),
                        this.R[i],
                    ),
                ),
            );
            A = tmp;
        }
        this.a0 = safeUintAdd(this.a0, A);
        this.b0 = safeUintAdd(this.b0, B);
        this.c0 = safeUintAdd(this.c0, C);
        this.d0 = safeUintAdd(this.d0, D);
    }

    calculate(strBuff) {
        // for every 512bit block of extended message...
        for (let i = 0; i < (strBuff.length / 64); i++) {
            // ... split block into 16 32bit LE integers and calculate the digest
            this.calculateDigest(this.calculateM(strBuff.slice(i * 64, (i + 1) * 64)));
        }
        return `${toHex(this.a0)}${toHex(this.b0)}${toHex(this.c0)}${toHex(this.d0)}`;
    }

    md5(string) {
        if (typeof string === 'string') {
            return this.calculate(this.prepareString(string));
        } else {
            return null;
        }
    }
}

export default string => (new MD5()).md5(string);
