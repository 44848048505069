<template>
    <div class="header-wrapper">
        <div class="version">
            <span class="env__label">{{ environment.name }}</span>
            <span v-if="environment.stage" class="env__label">({{ stage }})</span>
            <span v-if="environment.boxVersion" class="version_label" @click="openDocumentation"> v{{ environment.boxVersion }}</span>
        </div>
        <div class="language-select">
            <b-form-select
                data-test="PWLanguageSelection"
                class="language-select"
                :options="languageOptions"
                :translateOptions="false"
                size="sm"
                v-model="language">
            </b-form-select>
        </div>

        <documentation-modal
            v-if="showDocumentationModal"
            :releaseNotes="releaseNotes"
            :showModal.sync="showDocumentationModal">
        </documentation-modal>
    </div>
</template>

<script>
// TODO BOX-4176 split this into two Components, displaying environment information and release notes should be encapsulated in its own Component

import DocumentationModal from './DocumentationModal';
import md5 from '@/utils/hash';

const SEMVER_REGEX = /^([0-9]+)\.([0-9]+)\.([0-9]+)$/;

export default {
    name: 'panel-language-selection',
    components: {
        DocumentationModal,
    },
    data() {
        return {
            showDocumentationModal: false,
            environment: {
                name: '',
                stage: '',
                boxVersion: '',
            },
            languageOptions: [
                // value: @/types/Locale
                { text: 'Deutsch', value: 'de_DE' },
                { text: 'English', value: 'en_US' },
            ],
            releaseNotes: [],
        };
    },
    computed: {
        stage() {
            return this.$t(`env_stage_${this.environment.stage.toLowerCase()}`);
        },
        language: {
            get() {
                return this.$store.getters['userModule/language'];
            },
            set(value) {
                this.$store.dispatch('userModule/setLanguage', value);
            },
        },
        environmentState() {
            return this.$store.getters['environmentModule/environment'];
        },
    },
    watch: {
        environmentState(newValue, oldValue) {
            const isFirstChange = oldValue === null;

            if ((newValue !== oldValue) && !isFirstChange) {
                this.getEnvironment();
            }
        },
    },
    methods: {
        async getEnvironment() {
            let data;
            try {
                ({ data } = await this.$http.get(`${this.$config.api.confeedUrl}/env`));
            } catch (error) {
                this.$root.handleError(error, 'error_getting_environment');
                return;
            }
            this.environment = data;
            this.storeEnvironment(data);
        },
        async getReleaseNotes() {
            const { name } = this.$auth.user.profile;
            if (!name) {
                console.warn('Did not have a name to request release notes for');
                return;
            }
            const userIdentifier = md5(name);
            const version = this.environment.boxVersion;

            if (!SEMVER_REGEX.test(version)) {
                return;
            }

            let data;
            try {
                ({ data } = await this.$http.post(
                    `${this.$config.api.confeedUrl}/releasenotes`,
                    { userId: userIdentifier, version: this.environment.boxVersion },
                ));
            } catch (error) {
                this.$root.handleError(error, 'error_getting_release_notes');
                return;
            }
            this.showDocumentationModal = !data.alreadySeen;
            this.releaseNotes = data.releaseNotes;
        },
        storeEnvironment(data) {
            if (!this.environmentState) {
                this.$store.dispatch('environmentModule/setEnvironment', data);
            }
        },
        openDocumentation() {
            this.showDocumentationModal = true;
        },
    },
    async created() {
        await this.getEnvironment();
        await this.getReleaseNotes();
    },
};
</script>

<style scoped lang="scss">
    @import "../assets/scss/variables.scss";

    .header-wrapper {
        display: flex;
        align-items: center;
    }

    .version {
        margin-right: 20px;
    }

    .env__label {
        display: inline-block;
        padding: 0.5rem 5px;
        text-align: center;
        line-height: 1rem;
        font-family: $font-pw-light;
        font-size: 1rem;
        color: mix(black,$color-pw-blue, 20%);;
        text-transform: uppercase;
    }

    .version_label {
        cursor: pointer;
    }
</style>
