import routes from '@/router/routes';

const state = {
    routes,
};

const mutations = {
    'SET_VISIBILITY'(state, routeObj) { // routeObj := { name: 'routeName', visible: true }
        const namedRoute = findNamedRoute(state.routes, routeObj.name);
        if (namedRoute && namedRoute.showInMenu !== 'undefined') {
            namedRoute.showInMenu = routeObj.visible;
        }
    },
};

const actions = {
    setVisibility: ({ commit }, routeObj) => {
        commit('SET_VISIBILITY', routeObj);
    },
};

const getters = {
    routes: state => state.routes,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

function findNamedRoute(routes, routeName) {
    for (const route of routes) {
        if (route.name && route.name === routeName) {
            return route;
        }
        if (typeof route.routes !== 'undefined' && Array.isArray(route.routes) && route.routes.length > 0) {
            const currentRoute = findNamedRoute(route.routes, routeName);
            if (currentRoute) {
                return currentRoute;
            }
        }
        if (typeof route.children !== 'undefined' && Array.isArray(route.children) && route.children.length > 0) {
            const currentRoute = findNamedRoute(route.children, routeName);
            if (currentRoute) {
                return currentRoute;
            }
        }
    }
    return null;
}
