export enum DashboardType {
    // Order should reflect parent -> navigation bar order -> dashboard order
    // supplier
    CONVERTER_STATISTICS = 'CONVERTER_STATISTICS',
    EDF_DELIVERY_STATISTICS = 'EDF_DELIVERY_STATISTICS',
    LAST_EDF_DELIVERIES = 'LAST_EDF_DELIVERIES',
    SUPPLIER_INVENTORY_STATISTICS = 'SUPPLIER_INVENTORY_STATISTICS',
    CONTRACT_IMPORT_STATISTICS = 'CONTRACT_IMPORT_STATISTICS',
    // production
    PORTFOLIO_OVERVIEW = 'PORTFOLIO_OVERVIEW',
    PORTFOLIO_OVERVIEW_OVER_TIME = 'PORTFOLIO_OVERVIEW_OVER_TIME',
    WHITELIST_EXPORT_STATISTICS = 'WHITELIST_EXPORT_STATISTICS',
    PLAYER_PRODUCTION_STATISTICS = 'PLAYER_PRODUCTION_STATISTICS',
    // distribution
    CUSTOMER_TRAFFIC_STATS = 'CUSTOMER_TRAFFIC_STATS',
    REQUEST_TRAFFIC_STATS = 'REQUEST_TRAFFIC_STATS',
    SERVER_LOAD_STATISTICS = 'SERVER_LOAD_STATISTICS',
    LIVE_SEARCH_STATISTICS = 'LIVE_SEARCH_STATISTICS',
    BOOKING_STATISTICS = 'BOOKING_STATISTICS',
    BOOKING_SUPPLIER_PERFORMANCE = 'BOOKING_SUPPLIER_PERFORMANCE',
    LIVE_SEARCH_SUPPLIER_PERFORMANCE = 'LIVE_SEARCH_SUPPLIER_PERFORMANCE',
}

export type DashboardParent = 'distribution' | 'production' | 'supplier';

export interface DashboardInfo {
    type: DashboardType;
    routeName: string;
    parent: DashboardParent;
    sortOrder: number;
    showOnPartial?: boolean;
}

export const parents: Record<DashboardParent, { routeName: string; sortOrder: number }> = {
    supplier: {
        routeName: 'supply_insights_subparent',
        sortOrder: 1,
    },
    production: {
        routeName: 'production_insights_subparent',
        sortOrder: 2,
    },
    distribution: {
        routeName: 'distribution_insights_subparent',
        sortOrder: 3,
    },
};

export function getSortOrder(parent: DashboardParent) {
    if (parents[parent]) {
        return parents[parent].sortOrder;
    }
    return Number.MAX_VALUE;
}

export const Dashboards: Record<DashboardType, DashboardInfo> = {
    // supplier
    [DashboardType.CONVERTER_STATISTICS]: {
        type: DashboardType.CONVERTER_STATISTICS,
        routeName: 'converter_statistics',
        parent: 'supplier',
        sortOrder: 1,
    },
    [DashboardType.EDF_DELIVERY_STATISTICS]: {
        type: DashboardType.EDF_DELIVERY_STATISTICS,
        routeName: 'edf_delivery_statistics',
        parent: 'supplier',
        showOnPartial: true,
        sortOrder: 2,
    },
    [DashboardType.LAST_EDF_DELIVERIES]: {
        type: DashboardType.LAST_EDF_DELIVERIES,
        routeName: 'edf_delivery_statistics',
        parent: 'supplier',
        showOnPartial: true,
        sortOrder: 3,
    },
    [DashboardType.SUPPLIER_INVENTORY_STATISTICS]: {
        type: DashboardType.SUPPLIER_INVENTORY_STATISTICS,
        routeName: 'supplier_inventory_statistics',
        parent: 'supplier',
        sortOrder: 4,
    },
    [DashboardType.CONTRACT_IMPORT_STATISTICS]: {
        type: DashboardType.CONTRACT_IMPORT_STATISTICS,
        routeName: 'contract_import_statistics',
        parent: 'supplier',
        sortOrder: 5,
    },
    // production
    [DashboardType.PORTFOLIO_OVERVIEW]: {
        type: DashboardType.PORTFOLIO_OVERVIEW,
        routeName: 'portfolio_overview',
        parent: 'production',
        showOnPartial: true,
        sortOrder: 1,
    },
    [DashboardType.PORTFOLIO_OVERVIEW_OVER_TIME]: {
        type: DashboardType.PORTFOLIO_OVERVIEW_OVER_TIME,
        routeName: 'portfolio_overview',
        parent: 'production',
        showOnPartial: true,
        sortOrder: 2,
    },
    [DashboardType.WHITELIST_EXPORT_STATISTICS]: {
        type: DashboardType.WHITELIST_EXPORT_STATISTICS,
        routeName: 'whitelist_export_statistics',
        parent: 'production',
        sortOrder: 3,
    },
    [DashboardType.PLAYER_PRODUCTION_STATISTICS]: {
        type: DashboardType.PLAYER_PRODUCTION_STATISTICS,
        routeName: 'player_production_statistics',
        parent: 'production',
        sortOrder: 4,
    },
    // distribution
    [DashboardType.CUSTOMER_TRAFFIC_STATS]: {
        type: DashboardType.CUSTOMER_TRAFFIC_STATS,
        routeName: 'hub_statistics',
        parent: 'distribution',
        showOnPartial: true,
        sortOrder: 1,
    },
    [DashboardType.REQUEST_TRAFFIC_STATS]: {
        type: DashboardType.REQUEST_TRAFFIC_STATS,
        routeName: 'hub_statistics',
        parent: 'distribution',
        showOnPartial: true,
        sortOrder: 2,
    },
    [DashboardType.SERVER_LOAD_STATISTICS]: {
        type: DashboardType.SERVER_LOAD_STATISTICS,
        routeName: 'hub_statistics',
        parent: 'distribution',
        showOnPartial: true,
        sortOrder: 3,
    },
    [DashboardType.LIVE_SEARCH_STATISTICS]: {
        type: DashboardType.LIVE_SEARCH_STATISTICS,
        routeName: 'live_search_statistics',
        parent: 'distribution',
        showOnPartial: true,
        sortOrder: 4,
    },
    [DashboardType.BOOKING_STATISTICS]: {
        type: DashboardType.BOOKING_STATISTICS,
        routeName: 'booking_statistics',
        parent: 'distribution',
        sortOrder: 5,
    },
    [DashboardType.BOOKING_SUPPLIER_PERFORMANCE]: {
        type: DashboardType.BOOKING_SUPPLIER_PERFORMANCE,
        routeName: 'supplier_performance',
        parent: 'distribution',
        showOnPartial: true,
        sortOrder: 6,
    },
    [DashboardType.LIVE_SEARCH_SUPPLIER_PERFORMANCE]: {
        type: DashboardType.LIVE_SEARCH_SUPPLIER_PERFORMANCE,
        routeName: 'supplier_performance',
        parent: 'distribution',
        showOnPartial: true,
        sortOrder: 7,
    },
};

export default Dashboards;
