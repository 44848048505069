import axios from 'axios';
import config from '@/config';
import BaseResponse from '@/types/BaseResponse';

export interface FieldHint {
    key: string;
    description: string;
}

function fetchCompletions(id: string) {
    return axios.get<BaseResponse<Array<FieldHint>>>(
        `${config.api.portfolioBaseUrl}/autocomplete`,
        { params: { field: id } },
    );
}

export default {
    fetchCompletions,
};
