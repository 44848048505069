<template>
    <div id="app">
        <website-title-changer></website-title-changer>
        <star-def></star-def>
        <router-view></router-view>
        <vue-toastr ref="toastr"></vue-toastr>
        <pw-loading-layer data-test="PWLoadingLayer" ref="loadingLayer"></pw-loading-layer>
    </div>
</template>

<script>
import VueToastr from 'vue-toastr';
import PwLoadingLayer from 'pw-loading-layer';
import StarDef from '@/components/PerformantStarRatingDefs.vue';
import WebsiteTitleChanger from '@/components/WebsiteTitleChanger';

export default {
    name: 'app',
    components: {
        StarDef,
        VueToastr,
        PwLoadingLayer,
        WebsiteTitleChanger,
    },
};
</script>
