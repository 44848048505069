function applyMigrations(store) {
    const currentVersion = store.getters['userModule/feVersion'];
    if (currentVersion < 1) {
        migration1HideNewRoomDetailsColums(store);
    }
    if (currentVersion < 2) {
        migration2(store);
    }
    store.dispatch('userModule/setFeVersion', 2);
}

function migration1HideNewRoomDetailsColums(store) {
    store.dispatch('userModule/setColumnVisibility', { contextName: 'roomDetailsPage', col: { key: 'contractUuid', show: false } });
    store.dispatch('userModule/setColumnVisibility', { contextName: 'roomDetailsPage', col: { key: 'exportRoomKey', show: false } });
}

function migration2(store) {
    store.dispatch('userModule/setColumnVisibility', { contextName: 'whitelist', col: { key: 'infx', show: false } });
    store.dispatch('userModule/setColumnVisibility', { contextName: 'roomDetailsPage', col: { key: 'globalTypes', show: false } });
    store.dispatch('userModule/setColumnVisibility', { contextName: 'roomDetailsPage', col: { key: 'infx', show: false } });
}

export default {
    applyMigrations,
};
