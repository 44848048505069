import axios from 'axios';
import config from '@/config';

function fetchGoalResolutionDisabled() {
    return axios.get<boolean | null>(`${config.api.portfolioUrl}/extra/configuration/goalResolutionDisabled`);
}

export default {
    fetchGoalResolutionDisabled,
};
