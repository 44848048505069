import { routes } from '@peakwork/contentplayer-fe-plugin';
import { allPermissions } from '@/utils/authUtils';

const { config } = routes;

config.meta.secured = true;
config.meta.permissions = [allPermissions.CONTENT_CONFIGURATION];
config.meta.dataTestAttribute = 'PWContentPlayerContentConfiguration';

export default config;
