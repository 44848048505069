import configClient from '@/services/http/portfolio/configClient';
import roomMatchingClient from '@/services/http/portfolio/roomMatchingClient';

let resolveRoomBoardMatchingAssignmentPromise = null;
let resolveRoomAutoMatchingEnabledPromise = null;

function resetState(state) {
    state.roomBoardMatchingAssignment = null;
    state.roomBoardMatchingAssignmentPromise = new Promise((resolve) => {
        resolveRoomBoardMatchingAssignmentPromise = resolve;
    });
    state.roomAutoMatchingEnabled = null;
    state.roomAutoMatchingEnabledPromise = new Promise((resolve) => {
        resolveRoomAutoMatchingEnabledPromise = resolve;
    });
}

const state = {};
resetState(state);

const mutations = {
    'SET_ROOM_BOARD_MATCHING_ASSIGNMENT'(state, payload) {
        state.roomBoardMatchingAssignment = payload;
    },
    'SET_ROOM_AUTO_MATCHING_ENABLED'(state, enabled) {
        state.roomAutoMatchingEnabled = enabled;
    },
    'RESET_STATE'(state) {
        resetState(state);
    },
};

const actions = {
    async updateAfterSelectedBrandChanged({ commit, rootGetters }) {
        commit('RESET_STATE');

        const selectedBrandId = rootGetters['userModule/selectedBrandId'];
        if (!selectedBrandId) {
            return; // no brand selected
        }

        await fetchRoomBoardMatchingAssignment(commit, rootGetters, selectedBrandId);
    },
};

async function fetchRoomBoardMatchingAssignment(commit, rootGetters, selectedBrandId) {
    try {
        const response = await configClient.fetchRoomBoardMatchingAssignmentByBrandId(selectedBrandId);
        commit('SET_ROOM_BOARD_MATCHING_ASSIGNMENT', response.data.entity);
        resolveRoomBoardMatchingAssignmentPromise(response.data.entity);

        await fetchRoomAutoMatchingEnabled(commit, rootGetters, response.data.entity.roomMatchingConfigId);
    } catch (error) {
        const vueInstance = rootGetters['environmentModule/vueInstance'];
        vueInstance.$root.handleError(error, 'error_room_matching_configurations_call_failed');
    }
}

async function fetchRoomAutoMatchingEnabled(commit, rootGetters, roomMatchingConfigId) {
    try {
        const response = await roomMatchingClient.fetchRoomAutoMatchingEnabledByConfigId(roomMatchingConfigId);
        commit('SET_ROOM_AUTO_MATCHING_ENABLED', response.data.entity);
        resolveRoomAutoMatchingEnabledPromise(response.data.entity);
    } catch (error) {
        const vueInstance = rootGetters['environmentModule/vueInstance'];
        vueInstance.$root.handleError(error, 'error_room_matching_configurations_call_failed');
    }
}

const getters = {
    roomMatchingConfigId: state => state.roomBoardMatchingAssignment?.roomMatchingConfigId ?? null,
    boardAutoMatchingEnabled: state => state.roomBoardMatchingAssignment?.boardAutoMatching ?? null,
    roomBoardMatchingAssignmentPromise: state => state.roomBoardMatchingAssignmentPromise,
    roomAutoMatchingEnabled: state => state.roomAutoMatchingEnabled,
    roomAutoMatchingEnabledPromise: state => state.roomAutoMatchingEnabledPromise,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
