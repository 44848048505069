import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    name: 'lookerDashboardConfig',
    path: '/peakwork-admin/looker-dashboard-config',
    showInMenu: true,
    component: lazyLoading('peakworkAdmin', 'LookerDashboardConfig'),
    meta: {
        label: 'main_navigation.looker_dashboard_config',
        permissions: [allPermissions.INSIGHTS_WRITE],
        secured: true,
        dataTestAttribute: 'PWLookerDashboardConfig',
    },
};

export default routes;
