import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    name: 'masterdata',
    path: '/masterdata',
    component: lazyLoading('masterdata'),
    redirect: '/masterdata/hotelmasterdata',
    showInMenu: true,
    meta: {
        label: 'main_navigation.master_data',
        secured: true,
        dataTestAttribute: 'PWMasterData',
    },
    children: [{
        name: 'hotelmasterdata',
        path: '/masterdata/hotelmasterdata',
        showInMenu: true,
        component: lazyLoading('masterdata', 'HotelMasterData'),
        meta: {
            label: 'main_navigation.hotel_master_data',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.MASTER_HOTEL_READ, allPermissions.MASTER_CODE_SCHEMA_WRITE],
            dataTestAttribute: 'PWMasterDataHotel',
        },
    }, {
        name: 'roommasterdata',
        path: '/masterdata/roommasterdata',
        showInMenu: true,
        component: lazyLoading('masterdata', 'RoomMasterData'),
        meta: {
            label: 'main_navigation.rooms',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.ROOM_MATCHING_READ],
            dataTestAttribute: 'PWMasterDataRooms',
        },
    }, {
        name: 'boardmasterdata',
        path: '/masterdata/boardmasterdata',
        showInMenu: true,
        component: lazyLoading('masterdata', 'BoardMasterData'),
        meta: {
            label: 'main_navigation.boards',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.BOARD_MATCHING_READ],
            dataTestAttribute: 'PWMasterDataBoards',
        },
    }, {
        name: 'rates',
        path: '/masterdata/rates',
        showInMenu: true,
        component: lazyLoading('masterdata', 'PortfolioRates'),
        meta: {
            label: 'main_navigation.rates',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.RATE_MATCHING_READ],
            dataTestAttribute: 'PWMasterDataRates',
        },
    }, {
        name: 'remarks',
        path: '/masterdata/remarks',
        showInMenu: true,
        component: lazyLoading('masterdata', 'Remarks'),
        meta: {
            label: 'main_navigation.remarks',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.REMARK_READ],
            dataTestAttribute: 'PWMasterDataRemarks',
        },
    }, {
        name: 'globaltypegroups',
        path: '/masterdata/globaltypegroups',
        showInMenu: true,
        component: lazyLoading('masterdata', 'GlobalTypeGroups'),
        meta: {
            label: 'main_navigation.global_type_groups',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.GLOBALTYPE_GROUP_READ],
            dataTestAttribute: 'PWMasterDataGlobalTypeGroups',
        },
    }, {
        name: 'customairports',
        path: '/masterdata/customairports',
        showInMenu: false,
        component: lazyLoading('masterdata', 'CustomAirports'),
        meta: {
            label: 'main_navigation.custom_airports',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.CUSTOM_AIRPORTS_READ],
        },
    }],
};

export default routes;
