import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    name: 'portfolioconfiguration',
    path: '/portfolioconfiguration',
    showInMenu: true,
    component: lazyLoading('portfolioConfig'),
    meta: {
        label: 'main_navigation.portfolio_configuration',
        secured: true,
        showProcessSidebar: true,
        permissions: [allPermissions.PORTFOLIO_CONFIG_READ],
        dataTestAttribute: 'PWPortfolioConfiguration',
    },
};

export default routes;
