import axios from 'axios';
import BaseResponse from '@/types/BaseResponse';

export function isBaseResponse(axiosData: unknown): axiosData is BaseResponse<unknown> {
    if (axiosData && typeof axiosData === 'object') {
        return 'statusCode' in axiosData && 'errors' in axiosData && 'entity' in axiosData;
    }
    return false;
}

export function errorContainsBaseResponseCode(error: unknown, code: string) {
    if (!axios.isAxiosError(error)) {
        return false;
    }
    const axiosData = error.response?.data;
    if (!isBaseResponse(axiosData)) {
        return false;
    }
    if (!Array.isArray(axiosData.errors)) {
        return false;
    }
    return axiosData.errors.some(error => error.code === code);
}
