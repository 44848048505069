import ReconnectingWebSocket from 'reconnecting-websocket';
import migrationService from './migrations';

/**
 * This file is used to make calls before the app renders
 */
export class PanelInitializer {
    constructor(vueInstance) {
        this.vue = vueInstance;
        this.wsClient = null;
        this.retryInvervalInMilliseconds = 5000;
    }

    /**
     *  Use this place to add initial calls
     */
    async init() {
        this.vue.$store.commit('environmentModule/SET_VUE_INSTANCE', this.vue);
        migrationService.applyMigrations(this.vue.$store);
        this.initWebSocket();

        const optional = Promise.all([
            this.getExternalUrls(),
            this.checkForCustomAirports(),
            this.vue.$store.dispatch('insightsModule/loadDashboards'),
            this.vue.$store.dispatch('brandModule/updateAfterSelectedBrandChanged'),
        ]).catch(error => {
            // catch cannot be implemented on the await because errors could already occur while awaiting the required requests.
            if (error?.response.status >= 500) {
                this.vue.$log.warn('An optional initial service is not reachable');
            }
        });

        while (true) {
            const required = Promise.all([
                // All requests store their results in the vuex store directly
                // and show error popups on failure,
                // meaning we only need to retry and not handle the error here.
                this.getPrimaryHotelIdConfig(),
                this.getGlobalExtrasGoalResolutionDisabledConfig(),
            ]);

            try {
                await required;
                break;
            } catch (error) {
                if (error?.response.status >= 500) {
                    this.vue.$log.error('A required initial service is not reachable!');
                }
            }
            // block FE and retry every 5s
            this.vue.startLoadingLayer(0);
            await new Promise(resolve => setTimeout(resolve, this.retryInvervalInMilliseconds));
        }
        this.vue.stopLoadingLayer(0);

        await optional;
    }

    // ############################# External URLs ################################

    /**
     * Calls action of vuex which stores the external applications in store
     */
    getExternalUrls() {
        return this.vue.$store.dispatch('externalModule/getExternalApps', this.vue);
    }

    // ############################## Custom Airports #############################

    /**
     * Checks if custom airports are in use
     */
    async checkForCustomAirports() {
        let response;
        try {
            response = await this.vue.$http.get(`${this.vue.$config.api.portfolioUrl}/airport/custom/exist`);
        } catch (error) {
            this.vue.$log.error('Checking for custom airports failed!');
            return;
        }
        this.setVisibilityOfCustomAirportMenu(response.data.entity);
    }

    /**
     * Sets visibility of custom airport's menu item
     */
    setVisibilityOfCustomAirportMenu(customAirportsInUse) {
        this.vue.$store.dispatch('routesModule/setVisibility', { name: 'customairports', visible: customAirportsInUse });
    }

    // ######################### Primary Hotel ID config ##########################

    /**
     * Gets the primary hotel id configuration
     * @returns {Promise<any>}
     */
    getPrimaryHotelIdConfig() {
        return this.vue.$store.dispatch('environmentModule/fetchPrimaryHotelIdConfig');
    }

    /**
     * Gets the goal resolution disabled configuration of global extras
     * @returns {Promise<any>}
     */
    getGlobalExtrasGoalResolutionDisabledConfig() {
        return this.vue.$store.dispatch('environmentModule/fetchGoalResolutionDisabled');
    }

    // ############################## WebSocket ###################################

    /**
     * Initializes a websocket connection and subscribes the topics defined in config
     */
    initWebSocket() {
        this.wsClient = new ReconnectingWebSocket(this.vue.$config.websocket.url, [], { connectionTimeout: 10000 });

        // established connection send connection to receive last amq message
        this.wsClient.onopen = () => {
            this.wsClient.send('new client'); // Send the message 'Ping' to the server
        };

        // incoming message
        this.wsClient.onmessage = (msgEvent) => {
            try {
                if (msgEvent && msgEvent.data) {
                    const msg = JSON.parse(msgEvent.data);
                    this.storeProcessState(msg.message);
                }
            } catch (err) {
                this.vue.$log.error(`Parsing ws message failed: ${err}`);
            }
        };

        this.wsClient.onerror = (err) => {
            this.vue.$log.error('WebSocket Error ' + err.message);
        };
    }

    /**
     *  Stores the states in vuex
     * @param message the received message body
     */
    storeProcessState(message) {
        this.vue.$store.dispatch('portfolioWsModule/setProgress', message);
    }
}
