import { fetchExternalApps } from '@/services/http/portfolio/externalApplicationsClient';

const state = {
    externalApplications: [],
};

const mutations = {
    setExternalApplications: (state, payload) => {
        state.externalApplications = payload;
    },
};

const actions = {
    /**
     * Gets the urls of external applications
     * @returns {Promise}
     */
    getExternalApps: async (context, vueInstance) => {
        context.commit('setExternalApplications', await getExternalApplications(vueInstance));
    },
};

const getters = {
    externalApplications: state => state.externalApplications,
    contentUrl: state => {
        return getExternalApplicationsUrl(state, 'content-player');
    },
    lpacUrl: state => {
        return getExternalApplicationsUrl(state, 'lpac');
    },
    kibanaUrl: state => {
        return getExternalApplicationsUrl(state, 'kibana');
    },
    dhubUrl: state => {
        return getExternalApplicationsUrl(state, 'dhub');
    },
    ibeUrl: state => {
        return getExternalApplicationsUrl(state, 'ibe');
    },
};

async function getExternalApplications(vueInstance) {
    try {
        const response = await fetchExternalApps();
        return response.data;
    } catch (error) {
        vueInstance.$root.handleError(error, 'error_external_apps_call_failed');
        return [];
    }
}

// ########################## Helpers: ###############################

function getExternalApplicationsUrl(state, nameOfApplication) {
    if (state.externalApplications.length === 0) {
        return undefined;
    }

    return state.externalApplications
        .find(extApp => extApp.name.toLowerCase() === nameOfApplication)
        ?.url;
}

// ########################## /Helpers: ###############################

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
