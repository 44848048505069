import axios from 'axios';
import config from '@/config';
import PaginationResponse from '@/types/PaginationResponse';

// GOAL API
export interface LanguageString {
    value: string;
    language: string;
}

export interface GoalKeeperCityCriteria {
    cityId?: number | null;
    cityName?: string | null;
    countryCodes?: Array<string> | null;
    regionIds?: Array<number> | null;
    language?: string | null;
}

export interface GoalKeeperCity {
    cityId: number;
    // unsure if these are optional
    cityNames: Array<LanguageString>;
    regionId: number;
    regionNames: Array<LanguageString>;
    countryCode: string;
}

export interface GoalKeeperRegionCriteria {
    regionID: number | null;
    regionName: string | null;
    language: string | null;
}

export interface RegionDetails {
    regionName: string;
    regionID: number;
}

export interface GoalKeeperCountryCriteria {
    countryCode: string | null;
    countryNames: Array<LanguageString> | null;
    language: string | null;
}

export interface GoalKeeperCountry {
    countryCode: string;
    countryNames: Array<LanguageString>;
}

function fetchGoalkeeperCities(entity: GoalKeeperCityCriteria) {
    return axios.post<PaginationResponse<GoalKeeperCity>>(`${config.api.portfolioUrl}/goalkeeper/search/city`, { entity });
}

function fetchGoalkeeperCountries(entity: GoalKeeperCountryCriteria) {
    return axios.post<PaginationResponse<GoalKeeperCountry>>(
        `${config.api.portfolioUrl}/goalkeeper/search/country`,
        { entity },
    );
}

function fetchAllGoalkeeperCountries() {
    return axios.post<PaginationResponse<GoalKeeperCountry>>(
        `${config.api.portfolioUrl}/goalkeeper/search/country`,
        { limit: 1000000, entity: {} },
    );
}

function fetchGoalkeeperRegions(entity: GoalKeeperRegionCriteria) {
    return axios.post<PaginationResponse<RegionDetails>>(`${config.api.portfolioUrl}/goalkeeper/search/region`, { entity });
}

function fetchAllGoalkeeperRegions() {
    return axios.post<PaginationResponse<RegionDetails>>(
        `${config.api.portfolioUrl}/goalkeeper/search/region`,
        { limit: 1000000, entity: {} },
    );
}

// GIATA API

// currently unused
export interface ContractCityCriteria {
    cityName: string | null;
    language: string | null; // makes no sense
}

export interface ContractCity {
    cityName: string;
    language: string;
}

// currently unused
export interface ContractCountryCriteria {
    countryName: string | null;
    language: string | null; // makes no sense
}

export interface ContractCountry {
    countryName: string;
    language: string;
}

function fetchContractCities(cityName?: string | null) {
    return axios.post<PaginationResponse<ContractCity>>(
        `${config.api.portfolioUrl}/goalkeeper/contract/search/city`,
        { entity: { cityName } },
    );
}

function fetchAllContractCountries() {
    return axios.post<PaginationResponse<ContractCountry>>(
        `${config.api.portfolioUrl}/goalkeeper/contract/search/country`,
        { entity: {}, limit: 1000000 },
    );
}

export default {
    fetchGoalkeeperCities,
    fetchContractCities,
    fetchGoalkeeperCountries,
    fetchAllContractCountries,
    fetchGoalkeeperRegions,
    fetchAllGoalkeeperRegions,
    fetchAllGoalkeeperCountries,
};
