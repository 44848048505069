<template>
    <b-card
        :title="$t('migration_in_progress_title')"
        :sub-title="$t('migration_in_progress_subtitle')"
        data-test="PWMigrationDisplay"
    >
        <!-- progressbar -->
        <b-row v-for="(migration, index) in migrations" :key="index" class="spacing">
            <b-col cols="3">
                {{ migration.title }}
            </b-col>

            <b-col cols="2">
                <p v-if="index === 0 && migration.count"> {{ $t('migration_in_progress_items', [migration.count]) }}</p>
                <p v-else-if="index === 0 && !migration.count">{{ $t('migration_in_progress_active_no_items') }}</p>
                <p v-else>{{ $t('migration_in_progress_not_active') }}</p>
            </b-col>

            <b-col cols="7">
                <b-progress
                    data-test="PWMigrationProgressBar"
                    class="h-75 progressBarStyle"
                    :animated="index === 0"
                    :show-progress="migration.progress != null"
                    :max="migration.progress ? 100 : migration.count"
                >
                    <b-progress-bar
                        :label="migration.progress != null ? `${migration.progress} %` : ''"
                        :value="migration.progress != null ? migration.progress : (migration.count || 0)"
                    />
                </b-progress>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>

export default {
    name: 'MigrationDisplay',
    props: {
        migrations: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
    .spacing {
        margin-top: 25px;
    }
    .progressBarStyle {
        font-weight: bold;
        font-size: 125%;
    }
</style>
