import axios from 'axios';
import config from '@/config';
import BaseResponse from '@/types/BaseResponse';
import Description from '@/types/Description';
import RoomBoardMatchingConfig from '@/types/RoomBoardMatchingConfig';
import PaginationResponse from '@/types/PaginationResponse';
import PaginationRequest from '@/types/PaginationRequest';

export interface RoomMatchingTypeCriteria {
    configId?: string;
    code?: string;
}

export enum RoomMatchingContext {
    main = 'main',
    sub = 'sub',
    custom = 'custom',
}

type RoomType<T> =
    T extends RoomMatchingContext.main ? RoomMainType :
        T extends RoomMatchingContext.sub ? RoomSubType :
            T extends RoomMatchingContext.custom ? RoomCustomType :
                never;

export interface RoomMainType {
    configId: string | null;
    roomTag: RoomMainTag | null;
    code: string | null;
    descriptions: Array<Description> | null;
}

export interface RoomSubType {
    configId: string | null;
    roomTag: string | null;
    code: string | null;
    descriptions: Array<Description> | null;
}

export interface RoomCustomType {
    configId: string | null;
    roomTag: RoomMainTag | null;
    roomSubTags: Array<RoomSubTag> | null;
    code: string | null;
    descriptions: Array<Description> | null;
}

// Define as enums if necessary
type RoomMainTag = string;
type RoomSubTag = string;

function fetchRoomMatchingCodes<T extends RoomMatchingContext>(context: T, criteria: RoomMatchingTypeCriteria) {
    return axios.post<BaseResponse<Array<RoomType<T>>>>(`${config.api.portfolioUrl}/matching/room/type/${context}/search`, criteria);
}

function addRoomMatchingCode<T extends RoomMatchingContext>(context: T, payload: RoomType<T>) {
    return axios.put<BaseResponse<never>>(`${config.api.portfolioUrl}/matching/room/type/${context}/add`, payload);
}

function updateRoomMatchingCode<T extends RoomMatchingContext>(context: T, payload: RoomType<T>) {
    return axios.put<BaseResponse<never>>(`${config.api.portfolioUrl}/matching/room/type/${context}/update`, payload);
}

function deleteRoomMatchingCode(context: RoomMatchingContext, payload: RoomMatchingTypeCriteria) {
    return axios.delete<BaseResponse<never>>(`${config.api.portfolioUrl}/matching/room/type/${context}/`, { params: payload });
}

function fetchAllRoomMatchingConfigs() {
    return axios.get<BaseResponse<Array<RoomBoardMatchingConfig>>>(`${config.api.portfolioBaseUrl}/roomboardmatching/config/search`);
}

function fetchRoomAutoMatchingEnabledByConfigId(configId: string) {
    return axios.get<BaseResponse<boolean>>(`${config.api.portfolioBaseUrl}/roomboardmatching/config/active/${configId}`);
}

export interface RoomMatchingRuleCriteria {
    configId?: string;
    type?: string;
}

export interface RoomRule {
    typeCode: string | null;
    type: string | null;
    parameters: Record<string, string> | null;
    trimmedParameters: Record<string, string> | null;
    priority: number;
}

export interface RoomRuleModificationDto {
    configId: string;
    rules: Array<RoomRule> | null;
}

function fetchRoomRules(context: RoomMatchingContext.main | RoomMatchingContext.sub, payload: PaginationRequest<RoomMatchingRuleCriteria>) {
    return axios.post<PaginationResponse<RoomRule>>(
        `${config.api.portfolioUrl}/matching/room/rule/${context}/search`,
        payload,
    );
}

function addRoomRules(context: RoomMatchingContext.main | RoomMatchingContext.sub, payload: RoomRuleModificationDto) {
    return axios.put<BaseResponse<never>>(`${config.api.portfolioUrl}/matching/room/rule/${context}/add`, payload);
}

function updateRoomRules(context: RoomMatchingContext.main | RoomMatchingContext.sub, payload: RoomRuleModificationDto) {
    return axios.put<BaseResponse<never>>(`${config.api.portfolioUrl}/matching/room/rule/${context}/replace/all`, payload);
}

function putRoomMatchingConfiguration(payload: RoomBoardMatchingConfig) {
    return axios.put<BaseResponse<never>>(`${config.api.portfolioBaseUrl}/roomboardmatching/config/upsert`, payload);
}

export interface RoomBoardMatchingCopyConfig extends Omit<RoomBoardMatchingConfig, 'configId'> {
    sourceConfigId: string;
}

function copyRoomMatchingConfiguration(payload: RoomBoardMatchingCopyConfig) {
    return axios.post<BaseResponse<never>>(`${config.api.portfolioBaseUrl}/roomboardmatching/config/copy`, payload);
}

function deleteRoomMatchingConfiguration(payload: RoomBoardMatchingConfig) {
    return axios.delete<BaseResponse<never>>(`${config.api.portfolioBaseUrl}/roomboardmatching/config/remove`, { params: { configId: payload.configId } });
}

export interface RoomMasterCodeCombination {
    masterCode: string | null;
    mainType: RoomMainType | null;
    subTypes: Array<RoomSubType> | null;
    customType: RoomCustomType | null;
}

function getCustomRoomMasterCodeCombinations(brandConfigId: string) {
    return axios.get<BaseResponse<Array<RoomMasterCodeCombination>>>(`${config.api.portfolioUrl}/matching/room/mastercode/combinations`, { params: { brandConfigId } });
}

function getCustomRoomMasterCodeCombinationsAvailable(brandConfigId: string) {
    return axios.get<BaseResponse<boolean>>(`${config.api.portfolioUrl}/matching/room/mastercode/combinations/available`, { params: { brandConfigId } });
}

export interface BoardType {
    id: string | null;
    code: string | null;
    descriptions: Array<Description> | null;
    boardTag: BoardTag | null;
}

// Define as enum if necessary
type BoardTag = string;

function getCustomBoardMasterCodeCombinations() {
    return axios.get<BaseResponse<Array<BoardType>>>(`${config.api.portfolioUrl}/matching/board/mastercode/combinations`);
}

export default {
    addRoomMatchingCode,
    addRoomRules,
    copyRoomMatchingConfiguration,
    deleteRoomMatchingCode,
    deleteRoomMatchingConfiguration,
    fetchAllRoomMatchingConfigs,
    fetchRoomAutoMatchingEnabledByConfigId,
    fetchRoomMatchingCodes,
    fetchRoomRules,
    putRoomMatchingConfiguration,
    updateRoomMatchingCode,
    updateRoomRules,
    getCustomRoomMasterCodeCombinations,
    getCustomRoomMasterCodeCombinationsAvailable,
    getCustomBoardMasterCodeCombinations,
};
