/**
 * This module's purpose is to store completions for the generic ICM forms.
 *
 * Completions are queried and stored by a given key.
 */
import completionsClient from '@/services/http/portfolio/completionsClient';

function mapCompletions(data) {
    // currently fine-tuned for 'additionalattributes'
    return data.entity.map(item => ({
        value: item.key,
        text: item.description,
    }));
}

export default {
    namespaced: true,

    state: {
        optionsMap: new Map(),
        promises: new Map(),
    },

    mutations: {
        SET_COMPLETION_DATA(state, { id, options }) {
            if (options) {
                state.optionsMap.set(id, options);
            } else {
                state.optionsMap.delete(id);
            }
            state.promises.delete(id);
        },
        CLEAR_COMPLETION_DATA(state) {
            state.optionsMap.clear();
        },
        SET_PROMISE(state, { id, promise }) {
            state.promises.set(id, promise);
        },
    },

    actions: {
        async ensureCompletionsForId({ state, commit }, { id, force = false }) {
            if (state.promises.has(id)) {
                await state.promises.get(id);
            } else if (force || !state.optionsMap.has(id)) {
                const promise = completionsClient.fetchCompletions(id);
                commit('SET_PROMISE', { id, promise });
                const { data } = await promise;
                const options = mapCompletions(data);
                commit('SET_COMPLETION_DATA', { id, options });
            }
        },
        resetCompletions({ commit }) {
            commit('CLEAR_COMPLETION_DATA');
        },
        async refetchCompletions({ state, dispatch }) {
            const promises = [...state.optionsMap.keys()]
                .map(id => dispatch('ensureCompletionsForId', { id, force: true }));
            await Promise.all(promises);
        },
    },

    getters: {
        completionsForId: state => id => state.optionsMap.get(id) || [],
    },
};
