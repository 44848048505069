import { routes } from '@peakwork/contentplayer-fe-plugin';
import { allPermissions } from '@/utils/authUtils';

const { contentStore } = routes;

export const hotelList = contentStore[0];
hotelList.meta.secured = true;
hotelList.meta.permissions = [allPermissions.CONTENT_STORE];
hotelList.meta.dataTestAttribute = 'PWContentPlayerContentStore';

export const hotelDetails = contentStore[1];
hotelDetails.meta.secured = true;
hotelDetails.meta.permissions = [allPermissions.CONTENT_STORE];
hotelDetails.meta.dataTestAttribute = 'PWContentPlayerContentStoreDetails';
