/**
 * ATTENTION: child paths need to be absolute for proper comparison to $route.fullPath.
 */

import { routes as pwAuthRoutes } from 'pw-auth';
import authOptions from 'pw-auth/lib/options';
import { lazyLoading } from '@/utils/helpers';
import { mergeChildPermissionsRecursively } from '@/utils/authUtils';

const routes = [
    require('./routings/home').default,
    {
        label: 'main_navigation.portfolio_management',
        showInMenu: true,
        routes: [
            require('./routings/portfolioManagement/portfolio').default,
            require('./routings/portfolioManagement/masterdata').default,
            require('./routings/portfolioManagement/extras').default,
            require('./routings/portfolioManagement/configuration').default,
        ],
        meta: {
            secured: true,
        },
    },
    {
        label: 'main_navigation.dynamic_touroperator',
        showInMenu: true,
        routes: [
            require('./routings/dynamicTouroperator/distribution').default,
            require('./routings/dynamicTouroperator/referenceData').default,
            require('./routings/dynamicTouroperator/suppliers').default,
            require('./routings/dynamicTouroperator/brands').default,
            require('./routings/dynamicTouroperator/fulfillment').default,
            require('./routings/dynamicTouroperator/doris').default,
        ],
        meta: {
            secured: true,
        },
    },
    {
        label: 'main_navigation.nonbookablecontent',
        showInMenu: true,
        routes: [
            require('./routings/nonBookableContent/contentstore').hotelList,
            require('./routings/nonBookableContent/contentstore').hotelDetails,
            require('./routings/nonBookableContent/contentConfiguration').default,
        ],
        meta: {
            secured: true,
        },
    },
    {
        label: 'main_navigation.configuration',
        showInMenu: true,
        routes: [
            require('./routings/configuration/environment').default,
            require('./routings/configuration/account').default,
            require('./routings/configuration/expert').default,
        ],
        meta: {
            secured: true,
        },
    },
    {
        label: 'main_navigation.operation',
        showInMenu: true,
        routes: [
            require('./routings/operation/events').default,
        ],
        meta: {
            secured: true,
        },
    },
    {
        label: 'main_navigation.insights',
        showInMenu: false,
        name: 'insights_parent',
        routes: [
            require('./routings/insights/supplyInsights').default,
            require('./routings/insights/productionInsights').default,
            require('./routings/insights/distributionInsights').default,
        ],
        meta: {
            secured: true,
        },
    },
    {
        label: 'main_navigation.external',
        showInMenu: true,
        routes: [
            require('./routings/operation/external').default,
        ],
        meta: {
            secured: true,
        },
    },
    {
        label: 'main_navigation.peakworkAdmin',
        showInMenu: true,
        routes: [
            require('./routings/peakworkAdmin/lookerDashboardConfigs').default,
        ],
        meta: {
            secured: true,
        },
    },
    require('./routings/404').default,
];

routes.push(pwAuthRoutes[0]); // login
routes.push(pwAuthRoutes[1]); // token
routes.push({
    ...pwAuthRoutes[2],
    meta: {
        label: 'logout',
    },
});
routes.push({
    name: authOptions.app.accessDeniedRouteName,
    path: authOptions.app.accessDeniedRoutePath,
    component: lazyLoading('authorization', 'AccessDenied'),
    meta: {},
});

routes.forEach(route => mergeChildPermissionsRecursively(route));

export default routes;
