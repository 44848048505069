import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    alias: 'extras',
    path: '/extras',
    showInMenu: true,
    component: lazyLoading('extras'),
    meta: {
        label: 'main_navigation.extras',
        secured: true,
        permissions: [allPermissions.EXTRA_READ],
        dataTestAttribute: 'PWExtras',
    },
    children: [{
        name: 'extras',
        path: '',
        component: lazyLoading('extras', 'Overview'),
        meta: {
            label: 'main_navigation.extras',
            showProcessSidebar: true,
            secured: true,
            permissions: [allPermissions.EXTRA_READ],
        },
    }, {
        name: 'extras_show',
        path: ':id',
        component: lazyLoading('extras', 'ShowExtra'),
        meta: {
            label: 'main_navigation.extras',
            secured: true,
            permissions: [allPermissions.EXTRA_READ],
        },
    }],
};

export default routes;
