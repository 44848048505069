/* App */
import App from './App.vue';

/* external plugins */
import 'core-js/stable';
import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import axios from 'axios';
import VueAxios from 'vue-axios';
import FlagIcon from 'vue-flag-icon';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import PwCard from 'pw-card';
import VueAnalytics from 'vue-analytics';
// https://github.com/justinkames/vuejs-logger/issues/33#issuecomment-602581261
import VueLoggerPlugin from 'vuejs-logger/dist/vue-logger';
import PeakworkAuthPlugin from 'pw-auth';
import VCalendar from 'v-calendar';
import { DynamicReactiveRefs } from 'vue-reactive-refs';
import { VueQueryPlugin } from '@tanstack/vue-query';

/* internal configurations and definitions */
import router from './router';
import store from './vuex';
import vueConfig from './mixins/config';
import { i18n, i18nMixin } from './i18n';
import configs from './config';
import './validate';
import errorHandler from './init/globalErrorHandler';
import warningHandler from './init/globalWarningHandler';
import loadingLayer from './mixins/loadingLayer';
import authorizationMixin from './mixins/authorization';
import { PanelInitializer } from '@/init/PanelInitializer.js';
import ContentPlayerFePlugin, { translations as ContentPlayerTranslations } from '@peakwork/contentplayer-fe-plugin';
import MainLayout from '@/layouts/Main.vue';
import '@/assets/scss/app.scss';
import { setupTokenInterceptor, tokenInterceptor } from './interceptors/token';
import { setupTimeoutInterceptor } from './interceptors/timeout';
import { setupErrorInterceptor } from './interceptors/errorInterceptor';
import { register as registerPortfolioScoper } from '@peakwork/portfolio-scoper';
import { useToastr } from './composables/toastr';
import { useNotifications } from './composables/notifications';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';

setupTokenInterceptor();
setupErrorInterceptor();
setupTimeoutInterceptor();

Vue.config.productionTip = false;
Vue.config.errorHandler = errorHandler;
Vue.config.warnHandler = warningHandler;
Vue.config.ignoredElements = [
    'Teleport',
];

/* Plugins */
Vue.use(PeakworkAuthPlugin, {
    clientId: configs.ssoParams.clientId,
    clientSecret: configs.ssoParams.clientSecret,
    apiUrl: configs.ssoParams.serviceUrl,
    accountsUrl: configs.ssoParams.accountsUrl,
});

Vue.use(vueConfig, configs);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(FlagIcon);
Vue.use(VueAnalytics, configs.googleAnalytics);
Vue.use(VueLoggerPlugin, configs.logOptions);
Vue.use(VueAxios, axios);
Vue.use(DynamicReactiveRefs);
Vue.use(VCalendar, {});
Vue.use(VueQueryPlugin);
Vue.mixin(authorizationMixin);

Vue.use(ContentPlayerFePlugin, store, {
    contentPlayerApi: {
        url: configs.api.contentPlayerUrl,
        interceptor: tokenInterceptor,
    },
    layout: MainLayout,
});

i18n.mergeLocaleMessage('de_DE', ContentPlayerTranslations.de_DE);
i18n.mergeLocaleMessage('en_US', ContentPlayerTranslations.en_US);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

/* Components */
Vue.component('PwCard', PwCard);

// Should be disabled when migrated to Vue3
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Multiselect', Multiselect);

/* Web Components */
registerPortfolioScoper();

export const vueInstance = new Vue({
    el: '#app',
    mixins: [
        i18nMixin,
        loadingLayer,
    ],
    setup() {
        /** @deprecated Components that intend to use these should import them directly.
         * This is just a compatibility layer for the old mixins.
         */
        return {
            ...useToastr(),
            ...useNotifications(),
        };
    },
    data() {
        return {
            panelInitializer: null as PanelInitializer | null,
        };
    },
    i18n,
    router,
    store,
    pinia,
    render: h => h(App),
    async created() {
        // do not initialize the panel if the user is not logged in
        try {
            const previousSsoUrl = this.$store.getters['userModule/ssoUrl'];
            if (previousSsoUrl && previousSsoUrl !== window.__pwenv.SSO_SERVICE_URL) {
                // reset user info if environment has changed
                console.warn('SSO_SERVICE_URL changed');
                this.$store.dispatch('userModule/clearPermissions');
                this.$auth.logout();
            }
            this.$store.dispatch('userModule/setSsoUrl', window.__pwenv.SSO_SERVICE_URL);

            const isAuthenticated = await this.$auth.user.isAuthenticated();
            if (isAuthenticated) {
                this.panelInitializer = new PanelInitializer(this);
                await this.panelInitializer.init();
            }
        } catch (error) {
            this.$store.dispatch('userModule/clearPermissions');
        }
    },
    watch: {
        '$route.query.brandConfigId'(selectedBrandId: string | null) {
            // $route is not usable when this component is created or mounted,
            // so we need to watch for changes instead.
            if (selectedBrandId && selectedBrandId !== this.$store.getters['userModule/selectedBrandId']) {
                this.$store.dispatch('userModule/setSelectedBrandId', selectedBrandId);
            }
        },
    },
});
