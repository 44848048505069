import { lazyLoading } from '@/utils/helpers';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    name: 'home',
    path: '/',
    component: lazyLoading('home'),
    meta: {
        label: 'main_navigation.home',
        secured: true,
    },
};

export default routes;
