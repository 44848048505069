<template>
    <b-card
        v-click-outside="closeSidebar"
        no-body
        class="sidebar"
        :class="{open: isSidebarOpen}"
        data-test="PWProcessSidebar"
    >
        <div
            slot="header"
            @click="isSidebarOpen = !isSidebarOpen"
            class="sidebarHeader"
            data-test="PWProcessSidebarHeader"
        >
            <span class="fa" :class="[isSidebarOpen ? ' fa-arrow-right' : ' fa-arrow-left']"></span>
        </div>

        <div class="sidebarBody" data-test="PWProcessSidebarBody">
            <div :class="{grid: true, gridOpen: isSidebarOpen}">
                <template v-for="(process, index) in processes">
                    <!-- iteration -->

                    <!-- indicator -->
                    <div
                        :key="index"
                        @click="isSidebarOpen = !isSidebarOpen"
                        class="indicator"
                        :data-test="`PWProcessSidebarIndicator_${process.type}`"
                    >
                        <!-- badge -->
                        <template v-if="process.active">
                            <b-badge v-if="process.items" class="badge" variant="info">
                                {{ process.items.length }}
                            </b-badge>
                            <b-badge v-else class="badge" variant="info">1</b-badge>
                        </template>

                        <!-- abbr -->
                        <span class="abbr">{{ $t(process.abbr) }}</span>

                        <!-- progressbar -->
                        <template v-if="process.active">
                            <div v-if="process.progress !== null" class="progressBar">
                                <div
                                    class="progress"
                                    :style="`transform: translate3d(0, 0, 0) scaleX(${process.progress / 100})`"
                                />
                            </div>
                            <div v-else class="progressBar">
                                <div
                                    v-startAnimation
                                    ref="progressAnimated"
                                    :data-is-progress="true"
                                    class="progress progressAnimatedByScript"
                                />
                            </div>
                            <span v-if="Number.isInteger(process.progress) && isSidebarOpen">
                                {{ process.progress }}%
                            </span>
                        </template>
                    </div>

                    <!-- sidebar details -->
                    <div
                        v-if="isSidebarOpen"
                        :key="index+process.title"
                        class="description"
                        :data-test="`PWProcessSidebarDetails_${process.type}`"
                    >
                        <p><strong>{{ $t(process.title) }} </strong></p>
                        <p v-if="process.startedAt">
                            {{ $t('started_at') }}: {{ formatStartedDate(process.startedAt) }}
                        </p>

                        <!-- description -->
                        <p v-if="process.description && process.active">{{ $t(process.description) }}</p>
                        <p v-else>{{ $t('process_none') }}</p>

                        <b-list-group v-if="process.items && process.items.length > 0">
                            <b-list-group-item v-for="(item, index) in process.items" :key="item + index">
                                <div class="flex-fit">
                                    <template v-if="process.type === 'brandcopy'">
                                        <div>
                                            <table class="table-layout">
                                                <tr>
                                                    <th style="width: 30%">Source</th>
                                                    <th style="width: 45%">Target</th>
                                                    <th style="width: 25%">Progress</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span v-if="allBrands[item.brandConfigIds[0]]">
                                                            {{ allBrands[item.brandConfigIds[0]].code }}
                                                        </span>
                                                        <template v-else>
                                                            <i class="fa fa-exclamation-triangle"></i>
                                                            <span>
                                                                {{ retryOrGet(item.brandConfigIds[0]) }}
                                                                ({{ $t('unknown_brand') }})
                                                            </span>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <span v-if="allBrands[item.brandConfigIds[1]]">
                                                            {{ allBrands[item.brandConfigIds[1]].code }}
                                                        </span>
                                                        <template v-else>
                                                            <i class="fa fa-exclamation-triangle"></i>
                                                            <span>
                                                                {{ retryOrGet(item.brandConfigIds[1]) }}
                                                                ({{ $t('unknown_brand') }})
                                                            </span>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <span class="text-right" v-if="Number.isInteger(item.progress)">
                                                            <strong>{{ item.progress }}%</strong>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span>{{ $t("brand_copy_process_step") }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ item.description }}</span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            v-if="item.currentCount && item.maxCount"
                                                            class="text-right"
                                                        >
                                                            {{ item.currentCount }}/{{ item.maxCount }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </template>

                                    <template v-else>
                                        <div :key="brandConfigId" v-for="brandConfigId in item.brandConfigIds">
                                            <span v-if="allBrands[brandConfigId]">
                                                {{ allBrands[brandConfigId].name }}
                                            </span>
                                            <template v-else>
                                                <i class="fa fa-exclamation-triangle"></i>
                                                <span> {{ brandConfigId }} ({{ $t('unknown_brand') }})</span>
                                            </template>
                                        </div>
                                        <div :key="supplier" v-for="supplier in item.suppliers">
                                            <template v-if="allSuppliers[supplier]">
                                                <div class="flex-fit">
                                                    <span v-if="allSuppliers[supplier].usage === 'FlightOnly'">
                                                        <i class="fa fa-plane"></i>
                                                    </span>
                                                    <span v-if="allSuppliers[supplier].usage === 'HotelOnly'">
                                                        <i class="fa fa-home"></i>
                                                    </span>
                                                    <span> {{ allSuppliers[supplier].name }}</span>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <i class="fa fa-exclamation-triangle"></i>
                                                <span> {{ supplier }} ({{ $t('unknown_supplier') }})</span>
                                            </template>
                                        </div>
                                        <span> {{ item.description }}</span>
                                        <span v-if="item.currentCount" class="flex-grow text-right">
                                            <strong>({{ item.currentCount }})</strong>
                                        </span>
                                        <span v-if="item.progress" class="flex-grow text-right">
                                            <strong>{{ item.progress }}%</strong>
                                        </span>
                                    </template>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </template>
                <template v-if="processes.length <= 0">
                    <span class="abbr" data-test="PWProcessSidebarIndicator_noProcess">-</span>
                    <div v-if="isSidebarOpen" class="description" data-test="PWProcessSidebarDetails_noProcess">
                        <p class="mt-2">{{ $t('process_none') }}</p>
                    </div>
                </template>
            </div>
        </div>
    </b-card>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import moment from 'moment';
import { mapGetters } from 'vuex';
import brandClient from '@/services/http/portfolio/brandClient';

export default {
    data() {
        return {
            isSidebarOpen: false,
            allSuppliers: {},
            allBrands: {},
            animationFps: 25,
            animationProgress: 0,
            animationDirection: 1,
            animationDuration: 1000,
            animationCount: 0,
            enableAnimation: false,
            origin: 'left center',
            intervalId: null,
            timeoutId: null,
            alreadyRetriedBrandConfigIds: [],
        };
    },
    directives: {
        ClickOutside,
        startAnimation: {
            inserted: (el, binding, vnode) => {
                vnode.context.enableAnimation = true;
            },
            unbind: (el, binding, vnode) => {
                if (vnode.context.$refs.progressAnimated && !vnode.context.$refs.progressAnimated.length) {
                    vnode.context.enableAnimation = false;
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            processes: 'portfolioWsModule/allProcesses',
        }),
    },
    methods: {
        retryOrGet(brandConfigId) {
            if (!this.alreadyRetriedBrandConfigIds.includes(brandConfigId)) {
                // start async backend call that will be executed in parallel
                this.getBrandAndSupplierData();
                this.alreadyRetriedBrandConfigIds.push(brandConfigId);
            }
            return brandConfigId;
        },
        closeSidebar() {
            this.isSidebarOpen = false;
        },
        async getBrandAndSupplierData() {
            let response;
            try {
                response = await brandClient.getBrands();
            } catch (error) {
                this.$root.handleError(error, 'error_brand_call_failed');
                return;
            }
            this.mapBrandData(response.data.entity);
        },
        // maps to lists of all brands and all suppliers
        mapBrandData(brandData) {
            if (!brandData) {
                return;
            }

            const allSuppliers = {};
            const allBrands = {};

            brandData.forEach((brand) => {
                allBrands[brand.brandConfigId] = {};
                allBrands[brand.brandConfigId].name = brand.name;
                allBrands[brand.brandConfigId].code = brand.code;
                if (brand.supplier && brand.supplier.length) {
                    brand.supplier.forEach((supplier) => {
                        if (supplier.localSupplier.code) {
                            allSuppliers[supplier.localSupplier.code] = {};
                            allSuppliers[supplier.localSupplier.code].name = `${supplier.localSupplier.name} (${supplier.localSupplier.code})`;
                            allSuppliers[supplier.localSupplier.code].usage = supplier.localSupplier.usage;
                        }
                    });
                }
            });
            this.allBrands = allBrands;
            this.allSuppliers = allSuppliers;
        },
        animateProgress(/* timestamp */) {
            const timeoutId = setTimeout(() => {
                if (!this.$refs.progressAnimated || this.$refs.progressAnimated.length === 0) {
                    return;
                }

                const step = (1 / this.animationFps * this.animationDirection);
                this.animationProgress = (this.animationDirection === 1) ? Math.min(this.animationProgress + step, 1) : Math.max(this.animationProgress + step, 0);

                if (this.animationProgress >= 1 || this.animationProgress <= 0) {
                    this.animationDirection = this.animationDirection * -1;
                }

                if (this.animationProgress >= 1) {
                    this.origin = 'right center';
                }

                if (this.animationProgress <= 0) {
                    this.origin = 'left center';
                }

                this.$refs.progressAnimated.forEach((p) => {
                    if (p.dataset.progress === null) {
                        p.style.transformOrigin = 'left center';
                    } else {
                        const scale = Math.min(this.animationProgress, 1);

                        p.style.transform = `translate3d(0, 0, 0) scaleX(${scale})`;
                        p.style.transformOrigin = this.origin;
                    }
                });

                this.animationCount = this.animationCount + 1;

                if (this.enableAnimation) {
                    requestAnimationFrame(this.animateProgress);
                } else {
                    clearTimeout(timeoutId);
                }
            }, (this.animationDuration / 2 / this.animationFps));
        },
        enableProgressAnimation() {
            window.requestAnimationFrame = window.requestAnimationFrame
                || window.mozRequestAnimationFrame
                || window.webkitRequestAnimationFrame
                || window.msRequestAnimationFrame
                || function (f) { // simulate calling code 60
                    return setTimeout(f, 1000 / 60);
                };

            window.cancelAnimationFrame = window.cancelAnimationFrame
                || window.mozCancelAnimationFrame
                || function (requestID) { // fall back
                    clearTimeout(requestID);
                };
        },
        formatStartedDate(timestamp) {
            return moment.utc(timestamp).local().format(this.$t('date_time_format'));
        },
    },
    watch: {
        enableAnimation(newVal, oldVal) {
            if (newVal === true && newVal !== oldVal) {
                this.animationProgress = 0;
                this.animationDirection = 1;
                requestAnimationFrame(this.animateProgress);
                this.enableProgressAnimation();
            }
        },
    },
    async created() {
        await this.getBrandAndSupplierData();
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.sidebar {
    z-index: 10;
    position: fixed !important;
    right: 0;
    top: 100px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-height: calc(100vh - 200px);
}

.sidebarHeader {
    padding: 0.75rem 1.25rem;
    margin: -0.75rem -1.25rem;
    cursor: pointer;
}

.sidebarBody {
    overflow: auto;
    max-height: 70vh;

    @supports (display: grid) {
        max-height: initial;
    }
}

.sidebar.open {
    width: 440px;
}

.grid {
    display: flex; // IE
    flex-wrap: wrap; // IE
    flex-direction: column; // IE

    display: grid;
    grid-gap: 1em;
    margin: 1em 0.5em;
    grid-auto-rows: minmax(3.5em, auto);
}

// IE
.grid > * {
    margin-bottom: 1em;

    @supports (display: grid) {
        margin-bottom: 0;
    }
}

// IE
.gridOpen {
    flex-direction: row;
}

// IE
.gridOpen > * {
    &:nth-child(odd) {
        width: 3.5em;
    }

    &:nth-child(even) {
        margin-left: 1em;
        width: calc(100% - 3.5em - 1em);
    }

    @supports (display: grid) {
        width: auto;
    }
}

.gridOpen {
    grid-template-columns: auto 1fr;
}

.description {
    border-left: 2px solid pwColor('pewter', 3);
    padding: 0 0 1em 1em;
}

.description p:last-child {
    margin-bottom: 0;
}

.indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 3em;
    cursor: pointer;
}

.icon {
    display: inline-block;
    font-size: 2em;
}

.badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-25%);
}

.progressBar {
    width: 100%;
    height: 3px;
    margin-top: 3px;
    background-color: silver;
    position: relative;
}

.progress {
    height: 100%;
    width: 100%;
    background-color: black;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    transform-origin: left center;
}

.progressAnimatedByScript {
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: left center;
}

.abbr {
    height: 3em;
    width: 3em;
    border-radius: 50%;
    border: 2px solid pwColor('pewter', 3);
    text-align: center;
    line-height: 2.7em;
    font-weight: bold;
    white-space: nowrap;
    overflow: visible;
}

table {
    width: 100%;
    table-layout: fixed;
}

</style>
