import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    alias: '/brands',
    showInMenu: true,
    meta: {
        label: 'main_navigation.brands',
        secured: true,
        dataTestAttribute: 'PWMenuTouroperatorBrands',
    },
    children: [{
        alias: 'brands',
        path: '/brands/suppliers',
        showInMenu: true,
        component: lazyLoading('brands'),
        meta: {
            label: 'main_navigation.brands_overview',
            secured: true,
            permissions: [allPermissions.BRAND_READ],
            dataTestAttribute: 'PWMenuTouroperatorBrandsOverview',
        },
        children: [{
            name: 'brands',
            path: '',
            component: lazyLoading('brands', 'Table'),
            meta: {
                label: 'main_navigation.brands',
                secured: true,
                permissions: [allPermissions.BRAND_READ],
                writePermissions: [allPermissions.BRAND_WRITE],
            },
        }, {
            name: 'brands_register',
            path: '/brands/suppliers/register',
            component: lazyLoading('brands', 'Form'),
            meta: {
                label: 'main_navigation.brands',
                secured: true,
                permissions: [allPermissions.BRAND_WRITE],
            },
        }, {
            name: 'brands_edit',
            path: '/brands/suppliers/:id/edit',
            component: lazyLoading('brands', 'Form'),
            meta: {
                label: 'main_navigation.brands',
                secured: true,
                permissions: [allPermissions.BRAND_READ],
                writePermissions: [allPermissions.BRAND_WRITE],
            },
        }, {
            name: 'brands_copy',
            path: '/brands/suppliers/:id/copy',
            component: lazyLoading('brands', 'Copy'),
            meta: {
                label: 'main_navigation.brands_copy',
                secured: true,
                permissions: [allPermissions.BRAND_WRITE_COPY],
                showProcessSidebar: true,
            },
        }],
    }, {
        alias: 'brands_booking',
        path: '/brands/booking',
        showInMenu: true,
        component: lazyLoading('brands', 'booking/Index'),
        meta: {
            label: 'main_navigation.booking',
            extract: true,
            secured: true,
            permissions: [allPermissions.BOOKING_READ],
        },
        children: [{
            name: 'brands_booking',
            path: '',
            component: lazyLoading('brands', 'booking/Table'),
            meta: {
                label: 'main_navigation.booking',
                secured: true,
                permissions: [allPermissions.BOOKING_READ],
            },
        }, {
            name: 'brands_booking_register',
            path: 'register',
            component: lazyLoading('brands', 'booking/Form'),
            meta: {
                label: 'main_navigation.booking',
                secured: true,
                permissions: [allPermissions.BOOKING_WRITE],
            },
        }, {
            name: 'brands_booking_edit',
            path: ':id/edit',
            component: lazyLoading('brands', 'booking/Form'),
            meta: {
                label: 'main_navigation.booking',
                secured: true,
                permissions: [allPermissions.BOOKING_READ],
                writePermissions: [allPermissions.BOOKING_WRITE],
            },
        }],
    }, {
        alias: 'brands_packagetraveldirective',
        path: '/brands/packagetraveldirective',
        showInMenu: true,
        component: lazyLoading('brands', 'packagetraveldirective/Index'),
        meta: {
            label: 'main_navigation.package_travel_directive',
            extract: true,
            secured: true,
            permissions: [allPermissions.PACKAGE_TRAVEL_DIRECTIVE_READ],
        },
        children: [{
            name: 'brands_packagetraveldirective',
            path: '',
            component: lazyLoading('brands', 'packagetraveldirective/Table'),
            meta: {
                label: 'main_navigation.booking',
                secured: true,
                permissions: [allPermissions.PACKAGE_TRAVEL_DIRECTIVE_READ],
                writePermissions: [allPermissions.PACKAGE_TRAVEL_DIRECTIVE_WRITE],
            },
        }, {
            name: 'brands_packagetraveldirective_register',
            path: 'register',
            component: lazyLoading('brands', 'packagetraveldirective/Form'),
            meta: {
                label: 'main_navigation.booking',
                secured: true,
                permissions: [allPermissions.PACKAGE_TRAVEL_DIRECTIVE_WRITE],
            },
        }, {
            name: 'brands_packagetraveldirective_edit',
            path: ':id/edit',
            component: lazyLoading('brands', 'packagetraveldirective/Form'),
            meta: {
                label: 'main_navigation.booking',
                secured: true,
                permissions: [allPermissions.PACKAGE_TRAVEL_DIRECTIVE_READ],
                writePermissions: [allPermissions.PACKAGE_TRAVEL_DIRECTIVE_WRITE],
            },
        }],
    }, {
        name: 'brands_priceequalityrules',
        path: '/brands/priceequalityrules',
        showInMenu: true,
        component: lazyLoading('configuration', 'ConfigurationByRouting'),
        meta: {
            label: 'main_navigation.price_equality_rules',
            resource: 'priceequalityrules',
            hiddenFields: ['id', 'active'],
            secured: true,
            permissions: [allPermissions.PRICE_EQUALITY_READ],
        },
    }],
};

export default routes;
