<template>
    <div :data-test="pwDataTest" class="category">
        <span class="category__label">{{ label }}</span>
        <ul>
            <panel-recursive-navigation
                v-for="item in visibleItems"
                :key="item.name || item.alias"
                :item="item"
                :disabledNavigationChildren="disabledNavigationChildren"
            />
        </ul>
    </div>
</template>

<script>
import PanelRecursiveNavigation from './RecursiveNavigation.vue';

export default {
    name: 'panel-category-navigation',
    props: {
        disabledNavigationChildren: {
            type: Array,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
    },
    components: {
        PanelRecursiveNavigation,
    },
    computed: {
        label() {
            return this.$t(this.category.label);
        },

        visibleItems() {
            return this.category.routes.filter(item => item.showInMenu && this.permissions_sidebarItemHasPermission(item));
        },
        /**
         * Try to generate a name from the category.label to use for the tests as data-test attribute
         */
        pwDataTest() {
            return 'PW' + (this.category.label.length > 0 ? this.category.label.replace(/ /g, '') : 'NotFoundCategoryLabel');
        },
    },
};
</script>

<style scoped lang="scss">
    @import "../assets/scss/variables.scss";

    .category__label {
        display: block;
        padding: 0.5rem 5px;
        text-align: center;
        line-height: 1rem;
        font-family: $font-pw-light;
        font-size: 1rem;
        border-bottom: 1px solid $color-border;
        color: mix(black, $color-pw-blue, 20%);
        text-transform: uppercase;
    }

    .category:not(:first-child) .category__label {
        margin-top: 0.5rem;
    }

    ul {
        background-color: mix(white, $color-pw-grey, 90%);
        list-style: none;
        margin: 0;
        padding: 0;
    }
</style>
