import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const permissions = [allPermissions.INSIGHTS_READ];

const routes: PwRouteConfig = {
    showInMenu: false,
    name: 'distribution_insights_subparent',
    meta: {
        label: 'main_navigation.distribution',
        secured: true,
        permissions,
        dataTestAttribute: 'PWDistributionInsights',
    },
    children: [
        {
            name: 'hub_statistics',
            path: '/distribution-insights/hub-statistics',
            showInMenu: false,
            component: lazyLoading('distributionInsights', 'HubSearchStatistics'),
            meta: {
                label: 'main_navigation.hub_statistics',
                secured: true,
                permissions,
                dataTestAttribute: 'PWHubStatistics',
            },
        },
        {
            name: 'live_search_statistics',
            path: '/distribution-insights/live-search-statistics',
            showInMenu: false,
            component: lazyLoading('distributionInsights', 'LiveSearchStatistics'),
            meta: {
                label: 'main_navigation.live_search_statistics',
                secured: true,
                permissions,
                dataTestAttribute: 'PWLiveSearchStatistics',
            },
        },
        {
            name: 'booking_statistics',
            path: '/distribution-insights/booking-statistics',
            showInMenu: false,
            component: lazyLoading('distributionInsights', 'BookingStatistics'),
            meta: {
                label: 'main_navigation.booking_statistics',
                secured: true,
                permissions,
                dataTestAttribute: 'PWBookingStatistics',
            },
        },
        {
            name: 'supplier_performance',
            path: '/distribution-insights/supplier-performance',
            showInMenu: false,
            component: lazyLoading('distributionInsights', 'SupplierPerformance'),
            meta: {
                label: 'main_navigation.supplier_performance',
                secured: true,
                permissions,
                dataTestAttribute: 'PWSupplierPerformance',
            },
        },
    ],
};

export default routes;
