export default {
    methods: {
        /**
         * Get the LoadingLayer element from app container (currently the first child).
         * @returns {Object}
         */
        getLoadingLayer() {
            return this.$children[0].$refs.loadingLayer;
        },

        /**
         * Starts the LoadingLayer
         * instantly there will be a full transparent layer over the whole page,
         * after the configured timeout (in component) the spinner will be shown and the background gets a bit alpha
         */
        startLoadingLayer(startLayerTimeout) {
            this.getLoadingLayer().startLayer(startLayerTimeout);
        },

        /**
         * Stops and removes the LoadingLayer
         */
        stopLoadingLayer(stopLayerTimeout) {
            this.getLoadingLayer().stopLayer(stopLayerTimeout);
        },
    },
};
