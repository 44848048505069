import lookerDashboardConstants, { parents } from '@/utils/lookerDashboardConstants';
import dashboardClient from '@/services/http/portfolio/dashboardClient';
import cloneDeep from 'lodash/cloneDeep';
const state = {
    dashboardState: {},
};

const mutations = {
    'SET_DASHBOARD_ACTIVE'(state, payload) {
        const newState = cloneDeep(state.dashboardState);
        newState[payload.type] = payload.active;
        state.dashboardState = newState;
    },
};

const actions = {
    setDashboardActive: ({ commit, dispatch }, payload) => {
        if (!payload || payload.type === null || payload.active === null) {
            console.error('Invalid dashboard active set', payload);
            return;
        }
        commit('SET_DASHBOARD_ACTIVE', payload);
        activateDashboardState(state.dashboardState, dispatch, payload.type);
    },
    async loadDashboards({ dispatch }) {
        try {
            const activeStates = await dashboardClient.getDashboardsActiveState();
            activeStates.forEach(element => {
                dispatch('setDashboardActive', element);
            });
        } catch (error) {
            console.error('Failed to load DashboardActive state!', error);
        }
    },
};

const getters = {
    dashboardActive: state => (type) => state.dashboardState[type],
    getByParent: state => (group) => findChildren(state.dashboardState, group),
};

function activateDashboardState(dashboardState, dispatch, dashboardName) {
    try {
        const dashboard = lookerDashboardConstants[dashboardName];
        if (!dashboard) {
            console.error(`Dashboard ${dashboardName} is not known`);
            return;
        }
        const active = isDashboardActive(dashboardState, dashboard);
        const parentActive = isParentActive(dashboardState, dashboard.parent);
        dispatch(
            'routesModule/setVisibility',
            { name: lookerDashboardConstants[dashboardName].routeName, visible: active },
            { root: true },
        );
        dispatch(
            'routesModule/setVisibility',
            { name: parents[dashboard.parent].routeName, visible: parentActive },
            { root: true },
        );
        dispatch(
            'routesModule/setVisibility',
            { name: 'insights_parent', visible: dashboardState && Object.values(dashboardState).some(e => e) },
            { root: true },
        );
    } catch (e) {
        console.error(e);
    }
}

function isDashboardActive(dashboardState, dashboard) {
    const allRouteElements = byRouteName(dashboardState, dashboard.routeName);
    return dashboard.showOnPartial ? allRouteElements.some(e => e[1]) : allRouteElements.every(e => e[1]);
}

function isParentActive(dashboardState, parent) {
    const elements = findChildren(dashboardState, parent);
    return elements.some(e => isDashboardActive(dashboardState, e[0]));
}

function byRouteName(dashboardState, routeName) {
    return Object.entries(dashboardState).filter(entry => {
        const element = lookerDashboardConstants[entry[0]];
        return element && element.routeName === routeName;
    });
}

function findChildren(dashboardState, parent) {
    return Object.entries(dashboardState).filter(entry => {
        const element = lookerDashboardConstants[entry[0]];
        return element && element.parent === parent;
    });
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
