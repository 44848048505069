import axios from 'axios';
import config from '@/config';
import Permission from '@/types/permission';

async function fetchPermissions() {
    return axios.get<Array<Permission>>(`${config.api.mayflyBaseUrl}/auth/permissions`);
}

async function logout() {
    return axios.post<never>(`${config.api.mayflyBaseUrl}/auth/logout`);
}

export default {
    fetchPermissions,
    logout,
};
