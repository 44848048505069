import { AxiosError } from 'axios';
import { useI18n } from 'vue-i18n-composable';
import { BaseResponseError } from '@/types/BaseResponse';
import { useToastr } from './toastr';
import { useBvModal } from './bvModal';
import { inject } from 'vue';
import { isBaseResponse } from '@/utils/axiosUtils';

const KEY = Symbol('notifications_key');

export function useNotifications() {
    return inject(KEY, createNotifications, true);
}

export function createNotifications() {
    const { t, te } = useI18n();
    const toastr = useToastr();
    const bvModal = useBvModal();

    function handleError(error: unknown, template: string) {
        if (error instanceof Error && error.message.includes('Network Error')) {
            return toastr.notifyCritical(`${t(template)} <br> ${t('error_network_error')}`);
        }

        if (!((error as AxiosError).isAxiosError ?? false)) {
            return toastr.notifyError(t(`${t(template)} <br> ${error}`));
        }

        const axiosError = (error as AxiosError);
        const { response } = axiosError;
        if (axiosError.code === 'ECONNABORTED') {
            console.warn(`request to '${axiosError.config?.url}' aborted`);
            return;
        }

        if (!response?.status) { // unknown response structure
            console.error('Response structure unknown: ', response);
            return toastr.notifyCritical(`${t('error_unknown')} - ${t(template)}`);
        }

        let baseResponseErrors;
        if (isBaseResponse(response.data)) {
            baseResponseErrors = response.data.errors;
        }
        switch (response.status) {
            case 404:
            case 500:
            case 503:
            case 504:
                return toastr.notifyCritical(`${response.status} - ${t(template)}`);
            case 409:
                showPendingMessage({ responseErrorCode: baseResponseErrors?.[0]?.code }).catch(console.error);
                return;
            default:
                return handleErrorResponse(template, baseResponseErrors);
        }
    }

    function handleErrorResponse(template: string, errors?: Array<BaseResponseError> | null) {
        let msg = t(template);
        errors?.forEach(error => {
            switch (error.code) {
                case 'INTERNAL_SERVER_ERROR':
                    msg += ` <br/> ${t('error_internal_server_error')}`;
                    break;
                case 'NAME_ALREADY_EXISTS':
                    msg += ` <br/> ${t('error_code.NAME_ALREADY_EXISTS')}`;
                    break;
                default:
                    msg += ` <br/> ${error.message}`;
            }
        });
        return toastr.notifyCritical(msg);
    }

    async function showPendingMessage(
        options?: { responseErrorCode?: string | null; confirmText?: string; titleText?: string },
    ) {
        const translationKey = 'ERROR_' + options?.responseErrorCode;
        let confirmMessage = options?.confirmText ?? t('ERROR_BRAND_LOCKED');
        let titleMessage = options?.titleText ?? t('ERROR_BRAND_LOCKED_TITLE');
        if (te(translationKey) && te(translationKey + '_TITLE')) {
            confirmMessage = t(translationKey);
            titleMessage = t(translationKey + '_TITLE');
        }

        const result: boolean = await bvModal.msgBoxOk(confirmMessage, {
            title: titleMessage,
            centered: true,
        });
        return result;
    }

    return { handleError, showPendingMessage };
}
