import axios from 'axios';

export function setupErrorInterceptor() {
    /**
     * Interceptor (hooks in every request and response) to handle errors for all requests
     */
    axios.interceptors.response.use(
        response => response,
        function (error) {
            throw error;
        },
    );
}
