/**
 * Common functions for integers
 *
 * @author: Jan Hoffmann <jan.hoffmann@peakwork.com>
 * @version: 1.0
 */

/**
 * Converts a signed 32-Bit integer to unsigned
 * @param {int} integer 32-Bit signed integer
 * @since 1.0
 * @return {int} 32Bit uint
 */
const toUint32 = integer => (integer >>> 0);

/**
 * Converts a signed 8-Bit integer to unsigned
 * @param {int} integer 8-Bit signed integer
 * @since 1.0
 * @return {int} 8-Bit uint
 */
const toUint8 = (integer) => {
    let uint = 0;
    for (let i = 7; i >= 0; i--) {
        const power = Math.pow(2, i);
        if (integer & power) {
            uint += power;
        }
    }
    return uint % Math.pow(2, 8);
};

/**
 * Converts a 32-Bit integer to little endian format
 * @since 1.0
 * @param {int} integer 32-Bit Integer
 * @return {int} 32Bit int LE
 */
const toLittleEndian32 = (integer) => {
    let littleEndian = 0;
    let number = integer;
    for (let f = 0; f < 4; f++) {
        littleEndian |= (number & 0xff);
        number >>= 8;
        if (f !== 3) {
            littleEndian <<= 8;
        }
    }
    return littleEndian;
};

/**
 * Returns the hexadecimal value of integer (with leading zero)
 * @param {Number} integer 32-Bit integer
 * @since 1.0
 * @return {String} hexadecimal representation of 32-Bit value x
 */
const toHex = (integer) => {
    let byte = 0;
    let hexString = '';
    let byteHex;
    let number = integer;
    for (let f = 0; f < 4; f++) {
        byte = (number & 0xff);
        number >>= 8;
        byteHex = byte.toString(16);
        byteHex = ((byteHex.length === 2) ? byteHex : `0${byteHex}`);
        hexString = `${hexString}${byteHex}`;
    }
    return hexString;
};

/**
 * Safe unsigned integer add function
 * @param {Number} lX 32Bit integer
 * @param {Number} lY 32Bit integer
 * @since 1.0
 * @return {int} Sum of lX and lY as unsigned integer
 */
const safeUintAdd = (lX, lY) => toUint32((lX + lY));

/**
 * Bitwise rightrotation of x by n
 * @param {Number} number 32-Bit integer
 * @param {Number} times No. of times
 * @since 1.0
 * @return {int} rotated integer value
 */
const rotateLeft32 = (number, times) => (((number) << (times)) | ((number) >>> (32 - (times))));

/**
 * Bitwise leftrotation of x by n
 * @param {Number} number 32-Bit integer
 * @param {Number} times No. of times
 * @since 0.8
 * @return {int} rotated unsigned integer value
 */
const rotateRight32 = (number, times) => toUint32((((number) >>> (times)) | ((number) << (32 - (times)))));

export {
    toUint32,
    toUint8,
    toLittleEndian32,
    toHex,
    safeUintAdd,
    rotateLeft32,
    rotateRight32,
};
