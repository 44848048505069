import primaryHotelIdUtils from '@/utils/primaryHotelIdUtils';
import configClient from '@/services/http/portfolio/configClient';
import globalExtrasClient from '@/services/http/portfolio/globalExtrasClient';

let resolvePrimaryHotelIdConfig = null;
let resolveGoalResolutionDisabledConfig = null;

const state = {
    environment: null,
    vueInstance: null,
    primaryHotelIdConfig: null,
    primaryHotelIdConfigPromise: new Promise((resolve) => {
        resolvePrimaryHotelIdConfig = resolve;
    }),
    goalResolutionDisabledConfig: null,
    goalResolutionDisabledConfigPromise: new Promise((resolve) => {
        resolveGoalResolutionDisabledConfig = resolve;
    }),
};

const mutations = {
    'SET_ENV'(state, payload) {
        state.environment = { ...state.environment, ...payload };
    },
    'SET_VUE_INSTANCE'(state, payload) {
        state.vueInstance = payload;
    },
    'SET_PRIMARY_HOTEL_ID'(state, payload) {
        state.primaryHotelIdConfig = payload;
    },
    'SET_GOAL_RESOLUTION_DISABLED'(state, payload) {
        state.goalResolutionDisabledConfig = payload;
    },
};

const actions = {
    setEnvironment: ({ commit }, { name, stage, version }) => {
        commit('SET_ENV', { name, stage, version });
    },
    async fetchPrimaryHotelIdConfig({ state, commit }) {
        let data;
        try {
            ({ data } = await configClient.fetchPrimaryHotelIdConfig());
        } catch (error) {
            state.vueInstance.$root.handleError(error, 'error_primary_hotel_id');
            commit('SET_PRIMARY_HOTEL_ID', primaryHotelIdUtils.DEFAULT_VALUE);
            throw error;
        }
        const primaryHotelIdConfig = primaryHotelIdUtils.resolveConfig(data.entity?.primaryHotelId);
        commit('SET_PRIMARY_HOTEL_ID', primaryHotelIdConfig);
        resolvePrimaryHotelIdConfig(primaryHotelIdConfig);
        return primaryHotelIdConfig;
    },
    async fetchGoalResolutionDisabled({ state, commit }) {
        let goalResolutionDisabled;
        try {
            const response = await globalExtrasClient.fetchGoalResolutionDisabled();
            goalResolutionDisabled = response.data;
            commit('SET_GOAL_RESOLUTION_DISABLED', goalResolutionDisabled);
        } catch (error) {
            state.vueInstance.$root.handleError(error, 'global_extras_configuration_call_failed');
            commit('SET_GOAL_RESOLUTION_DISABLED', false);
            throw error;
        }
        resolveGoalResolutionDisabledConfig(goalResolutionDisabled);
        return goalResolutionDisabled;
    },
};

const getters = {
    environment: (state) => state.environment,
    primaryHotelIdConfig: (state) => state.primaryHotelIdConfig,
    primaryHotelIdConfigPromise: (state) => state.primaryHotelIdConfigPromise,
    goalResolutionDisabledConfig: (state) => state.goalResolutionDisabledConfig,
    goalResolutionDisabledConfigPromise: (state) => state.goalResolutionDisabledConfigPromise,
    vueInstance: state => state.vueInstance,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
