import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const permissions = [allPermissions.INSIGHTS_READ];

const route: PwRouteConfig = {
    name: 'production_insights_subparent',
    showInMenu: false,
    meta: {
        label: 'main_navigation.production',
        secured: true,
        permissions,
        dataTestAttribute: 'PWProductionDetails',
    },
    children: [
        {
            name: 'portfolio_overview',
            path: '/production-insights/portfolio-overview',
            showInMenu: false,
            component: lazyLoading('productionInsights', 'PortfolioOverview'),
            meta: {
                label: 'main_navigation.portfolio_overview',
                secured: true,
                permissions,
                dataTestAttribute: 'PWPortfolioOverview',
            },
        },
        {
            name: 'whitelist_export_statistics',
            path: '/production-insights/whitelist-export-statistics',
            showInMenu: false,
            component: lazyLoading('productionInsights', 'WhitelistExportStatistics'),
            meta: {
                label: 'main_navigation.whitelist_export_statistics',
                secured: true,
                permissions,
                dataTestAttribute: 'PWWhitelistExportStatistics',
            },
        },
        {
            name: 'player_production_statistics',
            path: '/production-insights/player-production-statistics',
            showInMenu: false,
            component: lazyLoading('productionInsights', 'PlayerProductionStatistics'),
            meta: {
                label: 'main_navigation.player_production_summary',
                secured: true,
                permissions,
                dataTestAttribute: 'PWPlayerProductionStatistics',
            },
        },
    ],
};

export default route;
