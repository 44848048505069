import { lazyLoading } from '@/utils/helpers';
import { allPermissions } from '@/utils/authUtils';
import { PwRouteConfig } from '@/types/routes';

const routes: PwRouteConfig = {
    name: 'environment',
    path: '/environment',
    showInMenu: true,
    component: lazyLoading('environment'),
    meta: {
        label: 'main_navigation.environment',
        dataTestAttribute: 'PWEnvironment',
        secured: true,
        permissions: [allPermissions.CONFIG_ENVIRONMENT],
    },
};

export default routes;
