import axios from 'axios';
import config from '@/config';
import BaseResponse from '@/types/BaseResponse';
import Brand from '@/types/Brand';
import { readonly } from 'vue';

export interface BrandNeedsExportDto {
    // Primitives on the backend cannot be `null`
    hasUnassignedHotels: boolean;
    customRateRules: boolean;
}

export const BrandNeedsExportDtoEmpty: BrandNeedsExportDto = readonly({
    hasUnassignedHotels: false,
    customRateRules: false,
});

export function getBrands() {
    return axios.get<BaseResponse<Array<Brand>>>(`${config.api.portfolioUrl}/brands`);
}

export function getBrandNeedsExport(brandConfigId: string) {
    return axios.get<BaseResponse<BrandNeedsExportDto>>(`${config.api.portfolioUrl}/brands/${brandConfigId}/needsexport`);
}

export default {
    getBrands,
    getBrandNeedsExport,
};
