import portfolioAssignmentsClient from '@/services/http/portfolio/portfolioAssignmentsClient';
import addressSearchClient from '@/services/http/portfolio/addressSearchClient';
import addressUtils from '@/utils/addressUtils';

const state = {
    filterDataMap: new Map(),
    pageNumberMap: new Map(),
    displayPageNumber: false,
    scope: null,
    // TODO replace the following 3 with goalData pinia store
    allRegions: null,
    allCountries: null,
    allContractCountries: null,
};

const mutations = {
    'SET_FILTER_DATA'(state, payload) {
        if (payload.contextName) {
            const currentFilterData = payload.currentFilter;
            state.filterDataMap.set(payload.contextName, currentFilterData);
        }
    },
    'CLEAR_FILTER_DATA'(state, contextName) {
        state.filterDataMap.delete(contextName);
    },
    'CLEAR_ALL_FILTER_DATA'(state) {
        state.filterDataMap.clear();
    },
    'SET_FILTER_PAGE_NUMBER'(state, payload) {
        if (payload.contextName) {
            state.pageNumberMap.set(payload.contextName, payload.pageNumber);
        }
    },
    'CLEAR_FILTER_PAGE_NUMBER'(state) {
        state.pageNumberMap.clear();
        state.displayPageNumber = false;
    },
    'SET_DISPLAY_PAGE_NUMBER_TRUE'(state) {
        state.displayPageNumber = true;
    },
    'SET_PORTFOLIO_SCOPE'(state, payload) {
        state.scope = payload;
    },
    'SET_ALL_REGIONS'(state, payload) {
        state.allRegions = payload;
    },
    'SET_ALL_COUNTRIES'(state, payload) {
        state.allCountries = payload;
    },
    'SET_ALL_CONTRACT_COUNTRIES'(state, payload) {
        state.allContractCountries = payload;
    },
};

const actions = {
    saveCurrentFilter: ({ commit }, payload) => {
        commit('SET_FILTER_DATA', payload);
    },
    clearCurrentFilter: ({ commit }, contextName) => {
        commit('CLEAR_FILTER_DATA', contextName);
    },
    clearAllFilterData: ({ commit }) => {
        commit('CLEAR_ALL_FILTER_DATA');
    },
    setCurrentFilterPageNumber: ({ commit }, payload) => {
        commit('SET_FILTER_PAGE_NUMBER', payload);
    },
    clearCurrentPageNumber: ({ commit }) => {
        commit('CLEAR_FILTER_PAGE_NUMBER');
    },
    displayCurrentPageNumber: ({ commit }) => {
        commit('SET_DISPLAY_PAGE_NUMBER_TRUE');
    },
    loadPortfolioScope: async ({ state, commit, rootGetters }) => {
        if (state.scope) {
            return state.scope;
        }
        const username = rootGetters['userModule/username'];
        const data = await portfolioAssignmentsClient.loadPortfolioScope(username);
        commit('SET_PORTFOLIO_SCOPE', data);
        return data;
    },
    loadRegions: async ({ state, commit, dispatch }, { filter, limit = 100000 }) => {
        if (!state.allRegions) {
            const allRegions = await addressSearchClient.fetchAllGoalkeeperRegions();
            const mapped = allRegions.data.entity
                .filter(region => region.regionName && region.regionID)
                .map(addressUtils.mapToRegionItem);
            mapped.sort(compareByKey('regionNameLower'));
            commit('SET_ALL_REGIONS', mapped);
        }
        if (!state.scope) {
            await dispatch('loadPortfolioScope');
        }
        return applyFiltersToRegion(state.allRegions, filter, limit);
    },
    loadContractCountries: async ({ state, commit, dispatch }, { filter, limit = 100000 }) => {
        if (!state.allContractCountries) {
            const allContractCountries = await addressSearchClient.fetchAllContractCountries();
            const mapped = allContractCountries.data.entity.map(({ countryName }) => ({
                // has no countryCode, only countryName
                countryName,
                countryNameLower: countryName.toLowerCase(),
            }));
            mapped.sort(compareByKey('countryNameLower'));
            commit('SET_ALL_CONTRACT_COUNTRIES', mapped);
        }
        if (!state.scope) {
            await dispatch('loadPortfolioScope');
        }
        return applyFiltersToCountry(state.allContractCountries, filter, limit);
    },
    loadGoalCountries: async ({ state, commit, dispatch, rootGetters }, { filter, limit = 100000 }) => {
        if (!state.allCountries) {
            const allCountries = await addressSearchClient.fetchAllGoalkeeperCountries();
            const language = rootGetters['userModule/language'];
            const mapped = allCountries.data.entity
                .map(country => addressUtils.mapToCountryItem(country, language));
            mapped.sort(compareByKey('countryNameLower'));
            commit('SET_ALL_COUNTRIES', mapped);
        }
        if (!state.scope) {
            await dispatch('loadPortfolioScope');
        }
        return applyFiltersToCountry(state.allCountries, filter, limit);
    },
};

function applyFiltersToCountry(data, filter = {}, limit = 100000) {
    if (state.scope && state.scope.countryCodes && state.scope.countryCodes.length > 0) {
        data = data.filter(country => state.scope.countryCodes.includes(country.countryCode));
    }
    if (filter.countryName) {
        const countryNameFilter = filter.countryName.toLowerCase();
        data = data.filter(country => country.countryNameLower.includes(countryNameFilter));
    }
    if (filter.countryCode) {
        data = data.filter(country => country.countryCode === filter.countryCode);
    }
    return data.slice(0, limit);
}

function applyFiltersToRegion(data, filter = {}, limit = 100000) {
    if (state.scope && state.scope.regionIds && state.scope.regionIds.length > 0) {
        data = data.filter(region => state.scope.regionIds.includes(region.regionId));
    }
    if (filter.regionName) {
        const regionNameFilter = filter.regionName.toLowerCase();
        data = data.filter(region => region.regionNameLower.includes(regionNameFilter));
    }
    if (filter.regionId) {
        data = data.filter(region => region.regionId === filter.regionId);
    }
    return data.slice(0, limit);
}

function compareByKey(key) {
    return (a, b) => (a[key] < b[key]) ? -1 : (a[key] > b[key]) ? 1 : 0;
}

const getters = {
    scope: state => state.scope,
    currentFilterData: state => (contextName) => {
        if (state.filterDataMap.has(contextName)) {
            return (state.filterDataMap.get(contextName));
        } else {
            return false;
        }
    },
    tablePageNumber: (state) => (contextName) => {
        if (state.pageNumberMap.has(contextName) && state.displayPageNumber) {
            return (state.pageNumberMap.get(contextName));
        } else {
            return 1;
        }
    },
    scopedCountries(state) {
        if (state.allCountries && state.scope && state.scope.countryCodes && state.scope.countryCodes.length > 0) {
            return applyFiltersToCountry(state.allCountries);
        }
        return [];
    },
    scopedRegions: (state) => {
        if (state.allRegions && state.scope && state.scope.regionIds && state.scope.regionIds.length > 0) {
            return applyFiltersToRegion(state.allRegions);
        }
        return [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
